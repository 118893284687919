import React, { Component } from 'react'

class CustomerTypeDt extends Component {
  render() {
    const styles = [
      {fill: ""},
      {fill: "#e72373"},
      {fill: "#d6f4fc"},
      {fill: "#ffebd2"},
      {fill: "#c79a83"},
      {fill: "#befa91"},
      {fill: "#e8f8fc"},
      {fill: "#f1fafc"},
      {fill: "#d0ffad"},
      {fill: "#bdeffc"},
      {fill: "#b6e783"},
      {fill: "#ffd6a6"},
      {fill: "#fff3e4"},
      {fill: "#e0b7a3"}
    ]

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="customer-type-dt"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
      >
        <title>dt</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path style={styles[1]} d="M25,0A25,25,0,0,0,10.3,45.22a7.73,7.73,0,0,1,7.48-5.63,3.14,3.14,0,0,0,3.14-3.13v-.2a10,10,0,0,1-5.73-8.69,3.07,3.07,0,0,1-2.37-3v-7A10.12,10.12,0,0,1,23,7.5h4.7A10.11,10.11,0,0,1,37.78,17.58v7a.76.76,0,0,1-.75.75.7.7,0,0,1-.25-.05,2.44,2.44,0,0,1-1.46,1.28v.61a10,10,0,0,1-6.48,9.37,3.55,3.55,0,0,0,0,.46v0a4.48,4.48,0,0,0,.2.74.76.76,0,0,1,1,.28,3.13,3.13,0,0,0,2.69,1.5,7.77,7.77,0,0,1,7.39,5.34A25,25,0,0,0,25,0Z"/>
            <path style={styles[2]} d="M14.27,47.58V45.34a6.85,6.85,0,0,0-.86,1.81C13.7,47.3,14,47.45,14.27,47.58Z"/>
            <path style={styles[2]} d="M36.23,45.34v2l.78-.4A7.34,7.34,0,0,0,36.23,45.34Z"/>
            <path style={styles[3]} d="M29,37.83l.08,0a4.48,4.48,0,0,1-.2-.74l.06.3a9.34,9.34,0,0,1-6.65.26,5,5,0,0,1-.37,1l3.33,3.69,1.33-1.51a.76.76,0,0,1,1.06-.07l0,0L29,39.25c-.09-.13-.18-.25-.27-.39A.75.75,0,0,1,29,37.83Z"/>
            <path style={styles[3]} d="M33.76,20.16a19.29,19.29,0,0,1-2,.07.77.77,0,0,1,.08.45.75.75,0,0,1-.74.65A25.79,25.79,0,0,1,25.74,20a26.12,26.12,0,0,1-2.55-1.1,4.69,4.69,0,0,1-1.73,3.24,6.83,6.83,0,0,0-2.72,5.51V30a1.14,1.14,0,0,1-1,1.21,8.69,8.69,0,0,0,3.78,3.64V33.19a3.77,3.77,0,0,1,7.54,0,.76.76,0,0,1-.76.75.76.76,0,0,1-.75-.75,2.26,2.26,0,0,0-4.52,0v2.24A8.57,8.57,0,0,0,33.82,27.2v-7S33.78,20.15,33.76,20.16Z"/>
            <path style={styles[4]} d="M36.28,24.61v-7a8.57,8.57,0,0,0-4.43-7.49,1.1,1.1,0,0,1-1.14,1H25.77a9.38,9.38,0,0,0-9.31,8.3A2.8,2.8,0,0,0,17.91,19a3.41,3.41,0,0,0,1.42-2.73.76.76,0,0,1,1.19-.62,24.4,24.4,0,0,0,10.67,4.22.77.77,0,0,1,.56.39h0a19.29,19.29,0,0,0,2-.07s0,0,.06,0v-.1a.75.75,0,1,1,1.5,0v6.53a2.44,2.44,0,0,0,1.46-1.28A.75.75,0,0,1,36.28,24.61Z"/>
            <path style={styles[5]} d="M34.46,43.51l-.18-.12-.37-.24-.12-.06c-.15-.09-.31-.17-.47-.25h0a7,7,0,0,0-1.83-.54l-.76,3.45a.76.76,0,0,1-1.31.33l-2.73-3.13c-.94,1.08-.89,1-1,1.09a.72.72,0,0,1-.64.1c-.25-.08-.26-.13-1.22-1.18l-2.7,3.1a.76.76,0,0,1-1.31-.32L19,42.29a7,7,0,0,0-1.83.54h0l-.48.25-.11.06-.37.24-.18.12-.26.2v4.53A25.06,25.06,0,0,0,34.72,48V43.83s0-.07,0-.11Z"/>
            <path style={styles[6]} d="M29,39.25h0l-1.34,1.48h0a.74.74,0,0,1,0,1.06L29.54,44l.78-3.53A4.86,4.86,0,0,1,29,39.25Z"/>
            <path style={styles[6]} d="M21,42.61c.07.33.14.67.22,1l1.55-1.79-1.45-1.6A3.58,3.58,0,0,0,21,42.61Z"/>
            <path style={styles[6]} d="M17.21,42.83A7,7,0,0,1,19,42.29h0A7,7,0,0,0,17.21,42.83Z"/>
            <path style={styles[6]} d="M15.78,43.71l.26-.2-.26.2Z"/>
            <path style={styles[7]} d="M21.35,40.22,21,39.81a5.83,5.83,0,0,1-.8.61L21,44l.29-.34c-.08-.33-.15-.67-.22-1A3.58,3.58,0,0,1,21.35,40.22Z"/>
            <path style={styles[8]} d="M18.75,41a14.24,14.24,0,0,0-3,.42v2.31l.26-.2.18-.12.37-.24.11-.06.48-.25h0A7,7,0,0,1,19,42.29Z"/>
            <path style={styles[9]} d="M11.63,46.12c.58.37,1.17.71,1.78,1a6.85,6.85,0,0,1,.86-1.81v-3.2A6.22,6.22,0,0,0,11.63,46.12Z"/>
            <path style={styles[9]} d="M35.48,41.73v1.35a.76.76,0,0,1,.75.75v1.51A7.34,7.34,0,0,1,37,46.93c.62-.34,1.22-.71,1.8-1.09A6.25,6.25,0,0,0,35.48,41.73Z"/>
            <path style={styles[10]} d="M32.73,41.1a4.46,4.46,0,0,1-1-.11l-.29,1.3h0a7,7,0,0,1,1.83.54h0c.16.08.32.16.47.25l.12.06.37.24.18.12.27.21h0a.76.76,0,0,1,.75-.64V41.73A6.31,6.31,0,0,0,32.73,41.1Z"/>
            <path style={styles[11]} d="M28.87,37.05v0a3.55,3.55,0,0,1,0-.46h0a10,10,0,0,1-6.42.25,4.2,4.2,0,0,1-.13.79,9.34,9.34,0,0,0,6.65-.26Z"/>
            <path style={styles[12]} d="M20.64,17.51a4.28,4.28,0,0,1-4,3.33V27.2a8.42,8.42,0,0,0,1,4,1.14,1.14,0,0,0,1-1.21V27.68a6.83,6.83,0,0,1,2.72-5.51,4.69,4.69,0,0,0,1.73-3.24A25.44,25.44,0,0,1,20.64,17.51Z"/>
            <path style={styles[13]} d="M27.66,9H23a8.6,8.6,0,0,0-8.62,8.58v7A1.53,1.53,0,0,0,15.19,26c0-6.43,0-6,.06-6.21a.77.77,0,0,1,.69-.44c.06,0,.25,0,.52,0a9.38,9.38,0,0,1,9.31-8.3h4.94a1.1,1.1,0,0,0,1.14-1A8.6,8.6,0,0,0,27.66,9Z"/>
            <path d="M32.72,39.57A3.13,3.13,0,0,1,30,38.07a.76.76,0,0,0-1-.28l-.08,0a.75.75,0,0,0-.25,1c.09.14.18.26.27.39h0a4.86,4.86,0,0,0,1.31,1.17L29.54,44l-1.88-2.16a.74.74,0,0,0,0-1.06h0l0,0a.76.76,0,0,0-1.06.07l-1.33,1.51-3.33-3.69a5,5,0,0,0,.37-1,4.2,4.2,0,0,0,.13-.79,10,10,0,0,0,6.42-.25h0a10,10,0,0,0,6.48-9.37V20.06a.75.75,0,1,0-1.5,0V27.2A8.57,8.57,0,0,1,23,35.43V33.19a2.26,2.26,0,0,1,4.52,0,.76.76,0,0,0,.75.75.76.76,0,0,0,.76-.75,3.77,3.77,0,0,0-7.54,0v1.67a8.69,8.69,0,0,1-3.78-3.64,8.42,8.42,0,0,1-1-4V20.84a4.28,4.28,0,0,0,4-3.33,25.44,25.44,0,0,0,2.55,1.42A26.12,26.12,0,0,0,25.74,20a25.79,25.79,0,0,0,5.35,1.3.75.75,0,0,0,.74-.65.77.77,0,0,0-.08-.45h0a.77.77,0,0,0-.56-.39,24.4,24.4,0,0,1-10.67-4.22.76.76,0,0,0-1.19.62A3.41,3.41,0,0,1,17.91,19a2.8,2.8,0,0,1-1.45.39c-.27,0-.46,0-.52,0a.77.77,0,0,0-.69.44c-.08.2-.06-.22-.06,6.21a1.53,1.53,0,0,1-.85-1.37v-7A8.6,8.6,0,0,1,23,9h4.7a8.63,8.63,0,0,1,8.62,8.58v7a.75.75,0,0,0,.5.7.7.7,0,0,0,.25.05.76.76,0,0,0,.75-.75v-7A10.11,10.11,0,0,0,27.66,7.5H23A10.12,10.12,0,0,0,12.82,17.58v7a3.07,3.07,0,0,0,2.37,3,10,10,0,0,0,5.73,8.69v.2a3.14,3.14,0,0,1-3.14,3.13,7.73,7.73,0,0,0-7.48,5.63l.65.46h0l.68.44a6.22,6.22,0,0,1,2.64-4v5.44l.75.34.76.32V43.71h0V41.4a14.24,14.24,0,0,1,3-.42L19,42.29h0l.76,3.44a.76.76,0,0,0,1.31.32l2.7-3.1c1,1,1,1.1,1.22,1.18a.72.72,0,0,0,.64-.1c.1-.07,0,0,1-1.09l2.73,3.13a.76.76,0,0,0,1.31-.33l.76-3.45h0l.29-1.3a4.46,4.46,0,0,0,1,.11,6.31,6.31,0,0,1,2.75.63,6.25,6.25,0,0,1,3.33,4.11c.23-.15.44-.3.66-.46h0c.22-.15.43-.31.64-.47A7.77,7.77,0,0,0,32.72,39.57ZM21,44l-.78-3.53a5.83,5.83,0,0,0,.8-.61l.37.41,1.45,1.6-1.55,1.79Z"/>
            <path d="M35.48,43.08a.76.76,0,0,0-.75.64h0s0,.07,0,.11V48l.76-.34.75-.37v-3.5A.76.76,0,0,0,35.48,43.08Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default CustomerTypeDt;
