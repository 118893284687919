import React, { Component } from 'react'

class LogoPublix extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      	 viewBox="0 0 537.31 121.23">
         <g id="Layer_2-2" data-name="Layer 2">
           <g>
             <path className="publix-logo" id="path3277" d="m0,39.08S.12,3.47,43.24,3.47c61.87,0,60.88,88.98,1.25,88.98-12.12,0-21.18-3.06-26.74-7.51v35.5H0V39.08Zm43.35-15.59c-30.14,0-30.52,47.45,0,47.45s30.14-47.45,0-47.45Z"/>
             <path className="publix-logo" id="path3279" d="m236.22,84.82s.11,35.62,43.24,35.62c61.4,0,58.81-88.98,1.25-88.98-12.12,0-21.18,3.06-26.74,7.51V3.47h-17.75v81.35h0Zm43.35,15.59c-30.2,0-30.54-47.45,0-47.45s30.24,47.45,0,47.45Z"/>
             <path className="publix-logo" id="path3281" d="m121.15,91.99l.02-55.98h20.22l.12,55.98s3.37,11.98,17.75,11.98,20.37-11.98,20.37-11.98l.11-55.98h18.66v55.98s-4.39,29.24-38.23,29.24-39.02-29.24-39.02-29.24Z"/>
             <path className="publix-logo" id="path3283" d="m356.81,5.97h20.25v114.47h-20.25V5.97Z"/>
             <path className="publix-logo" id="path3285" d="m413.7,36.46h20.26v83.98h-20.26V36.46Z"/>
             <path className="publix-logo" id="path3287" d="m437.18,12.4c0,6.85-5.55,12.4-12.4,12.4s-12.4-5.55-12.4-12.4,5.55-12.4,12.4-12.4,12.4,5.55,12.4,12.4h0Z"/>
             <path className="publix-logo" id="path3289" d="m491.9,77.37l-25.44-40.68h22.76l11.6,20.26,11.5-20.26h20.25l-20.36,40.62,25.1,43.13h-20.09l-13.83-22.76-11.66,22.76h-22.7l22.87-43.07h0Z"/>
           </g>
         </g>
      </svg>
    )
  }
}

export default LogoPublix;
