import React, { Component } from 'react'

class LogoNextech extends Component {
  render() {
    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181 30">
        <path d="M59.46,17.64c0,.77,0,2.05.09,3.85s.14,2.86.16,3.19h-.14L44.92,2.22H41.39V29.09h2.89V13.82c0-2.21-.1-4.63-.3-7.28h.15L58.81,29.09h3.57V2.22H59.46Z"/>
        <path d="M77.29,8.58a7.94,7.94,0,0,0-6.44,2.87,11.66,11.66,0,0,0-2.4,7.73q0,4.83,2.58,7.55a9.23,9.23,0,0,0,7,2.72,17.34,17.34,0,0,0,3.48-.3,16.47,16.47,0,0,0,3.06-1V25.43a16.15,16.15,0,0,1-6.43,1.36A6.18,6.18,0,0,1,73.4,25a8,8,0,0,1-1.79-5.38H85.48V17.64a9.54,9.54,0,0,0-2.2-6.57A7.6,7.6,0,0,0,77.29,8.58Zm-5.61,8.51a7.3,7.3,0,0,1,1.7-4.38,5,5,0,0,1,3.87-1.58,4.56,4.56,0,0,1,3.68,1.54,6.73,6.73,0,0,1,1.28,4.42Z"/>
        <polygon points="106.31 8.95 102.87 8.95 97.58 16.66 92.27 8.95 88.8 8.95 95.8 18.78 88.43 29.09 91.88 29.09 97.58 20.93 103.22 29.09 106.67 29.09 99.31 18.78 106.31 8.95"/>
        <path d="M117.43,26.94a3,3,0,0,1-2.37-1,4.1,4.1,0,0,1-.86-2.79V11.32H120V9H114.2V4.28h-1.77l-1.28,4.3-2.89,1.27v1.47h2.89v12q0,6.15,5.84,6.15a11.47,11.47,0,0,0,1.74-.15,5.78,5.78,0,0,0,1.46-.4V26.57a11.52,11.52,0,0,1-1.2.25A9.86,9.86,0,0,1,117.43,26.94Z"/>
        <path d="M132.19,8.58a8,8,0,0,0-6.44,2.87,11.66,11.66,0,0,0-2.4,7.73q0,4.83,2.59,7.55a9.22,9.22,0,0,0,7,2.72,17.51,17.51,0,0,0,3.49-.3,16.47,16.47,0,0,0,3.06-1V25.43a16.23,16.23,0,0,1-6.44,1.36A6.17,6.17,0,0,1,128.31,25a7.93,7.93,0,0,1-1.79-5.38h13.87V17.64a9.55,9.55,0,0,0-2.21-6.57A7.58,7.58,0,0,0,132.19,8.58Zm-5.6,8.51a7.3,7.3,0,0,1,1.7-4.38,5,5,0,0,1,3.87-1.58,4.54,4.54,0,0,1,3.67,1.54,6.68,6.68,0,0,1,1.29,4.42Z"/>
        <path d="M154,11.28a9.67,9.67,0,0,1,2.11.27,13.63,13.63,0,0,1,2.21.67l.93-2.59A9.27,9.27,0,0,0,157,8.89a13.71,13.71,0,0,0-2.91-.31,8.74,8.74,0,0,0-6.93,2.76c-1.63,1.83-2.44,4.44-2.44,7.8s.8,5.83,2.4,7.62a8.58,8.58,0,0,0,6.77,2.69,11,11,0,0,0,5.09-1V25.7a13.41,13.41,0,0,1-5.16,1.09,5.2,5.2,0,0,1-4.44-2,9.29,9.29,0,0,1-1.5-5.69Q147.89,11.28,154,11.28Z"/>
        <path d="M179,10.37q-1.85-1.76-5.54-1.75a8.3,8.3,0,0,0-3.8.83,6.21,6.21,0,0,0-2.56,2.29h-.19a18.52,18.52,0,0,0,.15-2.59V.49H164v28.6h3.05V18.56q0-3.93,1.46-5.66a5.76,5.76,0,0,1,4.64-1.73,4.55,4.55,0,0,1,3.51,1.21,5.26,5.26,0,0,1,1.12,3.68v13h3.05V16Q180.86,12.13,179,10.37Z"/>
        <path d="M17,15.62a3.91,3.91,0,0,0-3.47,1L1,27a3.08,3.08,0,0,0-.52.5,3.62,3.62,0,0,0,3.25,2h22l-6-11.62A3.92,3.92,0,0,0,17,15.62Z"/>
        <path d="M18.77,7a3.92,3.92,0,0,0,3.49-1l5.45-4.54A3.6,3.6,0,0,0,25.45.67H13.92l2.06,4A3.9,3.9,0,0,0,18.77,7Z"/>
        <path d="M14,6.4,11,.67H3.76A3.61,3.61,0,0,0,.14,4.28V24.4l.09-.06L12.34,14.26A6.74,6.74,0,0,0,14,6.4Z"/>
        <path d="M23.45,8.35a6.74,6.74,0,0,0-1.62,7.86l6.23,12.22a3.65,3.65,0,0,0,1-2.5V4.28a4.08,4.08,0,0,0,0-.61Z"/>
      </svg>
    )
  }
}

export default LogoNextech;
