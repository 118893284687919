import React, { Component } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import OverviewPanel from '../../../Components/Panels/Overview'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import PageHeader from '../../../Components/PageHeaders/Secondary'
import MasterDeck from '../../../decks/wm_case-study_master.pdf'
import ResearchDeck from '../../../decks/wm_case-study_research.pdf'
import TestingDeck from '../../../decks/wm_case-study_testing.pdf'
import CustomerJourneyDeck from '../../../decks/wm_customer-journey.pdf'
import WalmartHeroImage from '../../../images/walmart-hero-image.jpg'
import WalmartFlowImage from '../../../images/walmart-flow-display.jpg'
import WalmartUIVersionsDesktopImage from '../../../images/walmart_ui_versions_desktop.jpg'
import WalmartUIVersionsMobileImage from '../../../images/walmart_ui_versions_mobile.jpg'
import WalmartUIShowcaseDesktopImage1 from '../../../images/walmart_ui_showcase_desktop_d1.jpg'
import WalmartUIShowcaseDesktopImage2 from '../../../images/walmart_ui_showcase_desktop_d2.jpg'
import WalmartUIShowcaseMobileImage1 from '../../../images/walmart_ui_showcase_mobile_d1.jpg'
import WalmartUIShowcaseMobileImage2 from '../../../images/walmart_ui_showcase_mobile_d2.jpg'
import LinkWithIcon from '../../../Components/Links/IconLabel'

function mapStateToProps(state) {
  return { page: state.pageList.productPage };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class WalmartBackorder extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <PageHeader
            title="Walmart"
            time="2021 • Lead Designer"
            featuredImage={WalmartHeroImage}
            deck={MasterDeck}
            >
            <OverviewPanel>
              As a Senior UX Designer for Walmart, I worked on a project to improve the backorder behavior and allow shoppers a path to purchase items when back in stock. Through user research, our team designed a solution that enabled users to receive notifications when an item was back in stock via email and app push.
            </OverviewPanel>
          </PageHeader>
          <Container>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2>Gathering insights</h2>
                </header>
                <p>During our user research, we found that users usually bounced to another competitor to find the item, which led to lost revenue for Walmart. Interestingly, we also discovered that price was not always the deciding factor for users when it came to purchasing from Walmart or a competitor.</p>
              </Col>
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <LinkWithIcon className="work__link" to={ ResearchDeck } icon="slideshow">View Full Research Slides</LinkWithIcon>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2>Defining the problem</h2>
                </header>
                <p>Walmart’s CA and MX mobile app and website lacks an effective backorder system, which often results in users bouncing to a competitor's website to find out-of-stock items.</p>
                <p>Additionally, users are not currently able to receive notifications when an item becomes available again, which leads to frustration and missed opportunities for Walmart to capture sales. To improve the user experience and reduce lost revenue, we need to design and implement a backorder system that allows users to sign up for notifications when items are back in stock, and provides a seamless experience when adding the item to their cart.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartFlowImage } alt="Walmart Backorder Journey Map"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2 className="work-header__title">Uncovering User Entry Points</h2>
                </header>
                <p>Through mapping the customer journey, I discovered several entry points that the team had not considered, which led to a lack of consistency in the user experience.</p>
                <p>Additionally, I found that if users had the Walmart app installed, the email notification would prompt users to open the link in either the Walmart app or the web browser, which required us to broaden the scope of our design to include both the app and the web platform.</p>
              </Col>
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <LinkWithIcon className="work__link" to={ CustomerJourneyDeck } icon="pdf">View Customer Journey Maps</LinkWithIcon>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2 className="work-header__title">Creating Prototypes</h2>
                </header>
                <p>As part of Walmart's effort to adopt a new and improved design system, the team had to address the challenge of phasing in new components while maintaining a seamless user experience.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartUIShowcaseDesktopImage1 } alt="Walmart Backorder Desktop Initial Design"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartUIShowcaseMobileImage1 } alt="Walmart Backorder Mobile Initial Design"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2 className="work-header__title">Testing Solutions</h2>
                </header>
                <p> I worked closely with Walmart's research team to develop and execute a comprehensive test plan to evaluate our solutions. We discovered insights that changed the direction of our solution including:</p>
                <ol>
                  <li>We are missing an entry point for Walmart CA and MX</li>
                  <li>Users who were logged in expected the email field to be pre populated</li>
                  <li>Users were confusing the "Alert" CTA with the "Add To Cart" CTA</li>
                  <li>To adhere to the development requirements, we needed to utilize an existing pattern for the desktop version by redesigning it to fit inside a modal.</li>
                  <li>The full screen version of the mobile design did not reinforce which item they were signing up for.</li>
                </ol>
              </Col>
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <LinkWithIcon className="work__link" to={ TestingDeck } icon="slideshow">View Full Testing Slides</LinkWithIcon>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartUIVersionsDesktopImage } alt="Walmart Backorder Desktop Before and After"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartUIVersionsMobileImage } alt="Walmart Backorder Mobile Before and After"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <header className="work-header">
                  <h2 className="work-header__title">Revised Designs</h2>
                </header>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartUIShowcaseDesktopImage2 } alt="Walmart Backorder Desktop Final Design"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ WalmartUIShowcaseMobileImage2 } alt="Walmart Backorder Mobile Final Design"/>
              </Col>
            </Row>
            <Row className="work__block work__conclusion">
              <Col xs={8} push={{xs: 2}}>
                <Rating {...this.props} />
              </Col>
            </Row>
          </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(WalmartBackorder)
);
