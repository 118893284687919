import React, { Component } from 'react'
import PropTypes from 'prop-types'

class VideoHighlight extends Component {
  render () {
    const {src, title} = this.props

    let titleEl;

    if (title) {
      titleEl = <h3 className="video-highlight__title text--center">{title}</h3>
    }

    return (
      <div className="video-highlight">
        {titleEl}
        <video className="video-highlight" width="100%" autoPlay="1" loop="1" muted="1" playsInline="1">
          <source src={src} />
        </video>
      </div>
    )
  }
}

VideoHighlight.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string
}

export default VideoHighlight;
