import React, { Component } from 'react'

class Cloud extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="cloud"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 592 269"
      >
        <title>cloud</title>
        <path d="M479.25,80.09h0A26.44,26.44,0,0,1,505.44,53.4l54.34-.52A26.44,26.44,0,0,0,559.27,0h-527C17.62.14,5.9,13.31,6,27.91s12.09,30.16,26.69,30l38.86-.37,15.48-.15a26.44,26.44,0,0,1,26.69,26.18h0a26.44,26.44,0,0,1-26.18,26.7l-10.48.1a26.44,26.44,0,0,0,.51,52.87l34.08-.33h0l25.13-.24a26.44,26.44,0,0,1,26.69,26.19h0a26.44,26.44,0,0,1-26.19,26.69l-58,.55A26.44,26.44,0,0,0,79.84,269l436.89-4.19a26.44,26.44,0,0,0-.51-52.88l-2.42,0h0l-55.55.53a26.44,26.44,0,0,1-26.69-26.18h0a26.44,26.44,0,0,1,26.18-26.7l41-.39h0l18.25-.17a26.44,26.44,0,0,0-.51-52.88l-10.47.1A26.44,26.44,0,0,1,479.25,80.09Z"/>
      </svg>
    )
  }
}

export default Cloud;
