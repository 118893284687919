import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system'
import Auth from '../Auth'
import WorkLayout from '../../layouts/work'
import ContactForm from '../../Components/Forms/Contact'

class Login extends Component {
  constructor() {
    super();

    this.state = {
      redirectToReferrer: false,
      hasSubmitted: false,
      text: ''
    }
  }

  handleChange = (text) => {
    this.setState({ text })
  }

  login = () => {
    Auth.authenticate(this.state.text, () => {
      if (Auth.isAuthenticated) {
        this.setState({ redirectToReferrer: true })
      }

      this.setState({ hasSubmitted: true })
    })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { hasSubmitted, redirectToReferrer } = this.state

    let message

    if (hasSubmitted && !Auth.isAuthenticated) {
      message = <div className="error">Incorrect password. Check spelling and try again.</div>
    }

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <WorkLayout header="secondary">
        <div className="bio">
          <Container>
            <Row>
              <Col md={6} push={{ md: 3 }}>
                <p><span role="img" aria-label="shh">🤫</span> Top secret code required. I really hate to do this, please submit a request and I'll give you the code right away!</p>
                <ContactForm />
                <input placeholder="Top Secret Phrase" onChange={e => this.handleChange(e.target.value)} type="text"/>
                <button className="btn btn-secondary" onClick={this.login.bind(this)}>Log in</button>
                { message }
              </Col>
            </Row>
          </Container>
        </div>
      </WorkLayout>
    )
  }
}

export default withRouter(Login);
