import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import { Container, Row, Col } from 'react-grid-system'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import HeaderImg from '../../../images/doordash-header.jpg'
import ExistingImage from '../../../images/doordash-existing-experience.jpg'
import BackgroundImage from '../../../images/doordash-background-full.jpg'
import MobileHighFiImg1 from '../../../images/doordash-mobile-highfi-1.jpg'
import DesktopHighFiImg1 from '../../../images/doordash-desktop-highfi-1.jpg'
import JourneyMapImg from '../../../images/doordash-journey-map.jpg'
import Wireframe1Img from '../../../images/doordash-wireframe-concept-1.jpg'
import PageHeader from '../../../Components/PageHeaders/Secondary'

function mapStateToProps(state) {
  return { page: state.pageList.doordash };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class Doordash extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <PageHeader
            className="doordash__hero"
            backgroundImage={BackgroundImage}
            featuredImage={HeaderImg}
            title="Become a Dasher"
            time="2020"
            role="lead UX designer"
            prototypeId="#prototype"
            >
            <h3>First, you should know...</h3>
            <p>I worked on this project under a non-disclosure. The challenge we face as designers is how can we show off our work while protecting the privacy of the project? <span className="highlight">For this reason, I've chosen to replace all branding and content to reflect Doordash</span> because of how closely this aligns with this project.</p>
          </PageHeader>
        <Container>
          <Row className="work__block">
            <Col className="work__column" md={5} push={{ md: 1 }}>
              <h2>Understanding the current process</h2>
              <p>The current design was launched around a year prior and was designed in a stepper model. This is a common technique centered around the concept of exposing users to one step at a time, moving through a linear path to completion.</p>
              <p>A lot of our issues with the design were caused by poor implementation. <span className="highlight">We identified the following key problems holding us back:</span></p>
              <ol>
                <li>Each step took forever to load.</li>
                <li>Finding an enrolling Dasher was frustrating.</li>
                <li>Cognitive overload due to Marketing and Sales initiatives. You can tell from the zoomed out screenshot how overwhelming this view was.</li>
                <li>There were 11 total steps to completion at the time.</li>
              </ol>
            </Col>
            <Col className="work__column" xs={8} md={5} push={{ xs: 2, md: 1 }}>
              <img className="work__image" src={ExistingImage} alt="The Current Experience"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h2>Understanding the business goals, competitors, and problems</h2>
              <p>To begin I need to gather insights into how the current design is performing and understand the results to gain a baseline.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>User Feedback</h4>
              <p>The majority of user feedback came in the form of insights from customer service, social media comments, and user surveys. Some of the critical pain points we discovered were <b>A. The users were confused about the address step because it was not clear which checkboxes needed to be checked to proceed, B. Users were not clear on what to do next after joining,</b> and <b>C. The experience was too lengthy.</b></p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>Analyzing Current Design</h4>
              <p>I need to gain insights into how the current design is performing to identify areas of focus for the new design. Partnering with the Web Optimization Manager, we were able to identify the following key abandonment rates:</p>
              <ul>
                <li><b>25%</b> on the payment step</li>
                <li><b>33%</b> on selection of a dasher kit</li>
                <li><b>32.6%</b> on verify information step</li>
              </ul>
              <p>Another interesting find is that <b>over 99%</b> of enrolling users were choosing the cheapest enrollment dasher kit. Five variations were offered at various cost increments.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>Customer Journey Map</h4>
              <p>I put myself into the shoes of the users who are joining the business from the period of finding the opportunity through post joining,  allowing me to see and feel what they are experiencing and identify pain points along the way.</p>
              <img className="work__image" src={JourneyMapImg} alt="customer journey map"/>
              <h6 className="work__caption">Created with <a href="https://www.miro.com/" target="_blank" rel="noopener noreferrer">Miro</a>: Parts are blurred to protect sensitve information</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h2>The Solution</h2>
              <p>Armed with what I've learned from my research, I now had a pretty good idea of how I needed to approach this new design. This would not prove easy however.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>1. Remove Extra Kits</h4>
              <p>It became clear to us that we needed to convince Marketing and Sales to remove the other available kits. <span className="highlight">This would eliminate our highest abandonment step and reduce significant cognitive load</span>. Less than 1% of enrolling Dasher's selected another kit.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>2. Defer SSN Step</h4>
              <p>Our biggest hurdle would prove to be removing the SSN step from the beginning of the process since this was a legal requirement in an attempt to reduce fraud. However, this was not a secure step at all. To convince legal to allow this, I needed to find another way.</p>
              <p><span className="highlight">I came up with the idea to utilize an authorization process users are familiar with that will be more secure – phone number verification</span>. Mobile web browsers are also helping to make this easier with auto input from text features. This was approved to move forward.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>3. Create a 3-Step Join Process</h4>
              <p>The concept for the new design was centered around <span className="highlight">removing friction, only capturing the necessary information upfront to get the users signed up</span>. Next, we will focus on getting you paid by capturing your SSN and hand you off to the app. The idea is by wording this step as "getting you paid", at this point, users will feel more comfortable giving this information because they see benefits in doing so.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h2 className="work__heading">Deciding between two ideas</h2>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <Row>
                <Col md={6}>
                  <img className="work__image" src={Wireframe1Img} alt="Doordash Concepts"/>
                  <h6 className="work__caption">Created with <a href="https://scribbletogether.com/" target="_blank" rel="noopener noreferrer">Scribble Together</a> on iPad Pro.</h6>
                </Col>
                <Col md={6}>
                  <h4>Concept #1: Hub and Spoke Model</h4>
                  <p>The hub and spoke model is great when you need to provide users with an Overview of the process while focusing on one task at a time.</p>
                  <br/>
                  <h4>Concept #2: Stepper Model</h4>
                  <p>This approach would utilize casual conversation as the basis for walking users swiftly through the process. This works best to focus the users on one task at a time in a particular order to completion.</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h4>Why We Chose The Stepper Model</h4>
              <p>During our research, we fell in love with how Carvana was able to simplify one of the worst buying experiences using the hub and spoke model. Initially, we wanted to base our design around this concept. However, when thinking through this approach we realized this would not work because our steps needed to be completed in a specific order. <span className="highlight">This made it clear that we needed to move forward with the conversational stepper approach.</span></p>
            </Col>
          </Row>
          <Row id="prototype">
            <Col md={8} push={{md: 2}}>
              <h2 className="work__heading">Low-fi prototype</h2>
            </Col>
          </Row>
          <Row className="work__block">
            <Col xs={8} push={{xs: 2}}>
              <iframe title="doordash-lowfi-prototype" className="work__prototype" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FMFf0xS3lKnAERyPBhNZjtU%2FDD-Join-Flow-Low-Fi%3Fnode-id%3D600%253A114%26scaling%3Dcontain&chrome=DOCUMENTATION" allowFullScreen></iframe>
              <h6 className="work__caption">Images missing? Try using Safari or disabling chrome extensions.</h6>
            </Col>
          </Row>
          <Row className="work__block" id="highfi">
            <Col xs={8} push={{xs: 2}}>
              <h2 className="work__heading">Final Designs</h2>
              <br/>
              <img className="work__image" src={MobileHighFiImg1} alt="Mobile Final Designs"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col xs={8} push={{xs: 2}}>
              <img className="work__image" src={DesktopHighFiImg1} alt="Desktop Final Designs"/>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(Doordash)
);
