import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../../../icons'

class LinkWithIcon extends Component {
  renderClick(e) {
    e.preventDefault();

    this.props.onClick();
  }

  render() {
    const {to, icon, className, children} = this.props

    return (
      <a className={classnames({[`link-with-icon`]: true}, className)} href={ to } target="_blank" rel="noopener noreferrer">
          <Icon className="link-with-icon__icon" name={ icon } />
          {children}
      </a>
    )
  }
}

LinkWithIcon.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string
}

export default LinkWithIcon;
