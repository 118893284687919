import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class ColorBlock extends Component {
  renderClick(e) {
    e.preventDefault();

    this.props.onClick();
  }

  render() {
    const {children, title, color, className, isDecor, isCenter, isHero, isSwiggly, swigglyColor} = this.props

    const headingClasses = {
      'swiggly': isSwiggly,
      'text--center': isCenter,
      'color-block__heading--decor': isDecor,
      'color-block__heading': true
    }

    let headerEl

    if (title) {
      headerEl = <header className={classnames('color-block__header')}>
        <h2 className={classnames(headingClasses, swigglyColor)}>{title}</h2>
      </header>
    }

    return (
      <div className={classnames('color-block', className, color, {'hero': isHero})}>
        {headerEl}
        {children}
      </div>
    )
  }
}

ColorBlock.propTypes = {
  isHero: PropTypes.bool,
  isDecor: PropTypes.bool,
  isCenter: PropTypes.bool,
  isSwiggly: PropTypes.bool,
  swigglyColor: PropTypes.bool,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string
}

ColorBlock.defaultProps = {
  isDecor: false,
  isHero: false,
  isCenter: false,
  isSwiggly: true,
  swigglyColor: ''
}

export default ColorBlock;
