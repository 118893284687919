import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../icons'
import classnames from 'classnames'

class Rating extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmitted: false,
      isNegative: false,
      value: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.submitNegativeFeedback = this.submitNegativeFeedback.bind(this);
    this.handleNegativeFeedback = this.handleNegativeFeedback.bind(this);
  }

  handleSubmit = (rating) => {
    const _path = this.props.match.path.split("/work/").pop();

    this.props.addFeedback(_path, rating, this.state.value);
    this.setState({ isSubmitted: true })
  }

  handleNegativeFeedback = () => {
    this.setState({ isNegative: true })
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  submitNegativeFeedback = (e) => {
    e.preventDefault();

    this.handleSubmit('2')
  }

  renderCommentForm = () => {
    if (this.state.isNegative && !this.state.isSubmitted) {
      return (
        <div className="rating-form">
          <form onSubmit={this.submitNegativeFeedback} id="rating-form">
            <textarea
                className="rating-form__comment"
                value={this.state.value}
                onChange={this.handleChange}
                placeholder="How can I improve? No worries, your feedback is annonymous."
                />
          </form>
          <button
              className="rating-form__button btn btn-secondary"
              type="submit"
              form="rating-form"
              value="submit">
              Submit Feedback
          </button>
        </div>
      )
    }
  }

  render() {
    const {isSubmitted, isNegative} = this.state

    let successDiv
    let successMessage
    let buttonEl

    isNegative
      ? successMessage = "I've received your feedback. Thanks for helping me improve my portfolio!"
      : successMessage = "I'm glad you've enjoyed my story!"

    if (isSubmitted) {
      successDiv = <div className="rating__success">{successMessage}</div>
    } else {
      buttonEl = <div className="rating__icon-group">
        <button className="rating__icon rating__icon--like" onClick={this.handleSubmit.bind(this, '1')}>
          <Icon className="rating__icon" name="like" />
        </button>
        <button className={classnames("rating__icon rating__icon--dislike", {"active": this.state.isNegative})} onClick={this.handleNegativeFeedback}>
          <Icon className="rating__icon" name="dislike" />
        </button>
      </div>
    }

    return (
      <div className="rating">
        <header className="rating__header">
          <h2 className="rating__title">Thanks for reading :)</h2>
          <h4 className="rating__subtitle">How'd I Do? Your feedback helps me grow!</h4>
        </header>
        {buttonEl}
        {successDiv}
        {this.renderCommentForm()}
      </div>
    );
  }
}

Rating.propTypes = {
  addFeedback: PropTypes.func.isRequired
}

export default Rating;
