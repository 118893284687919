import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import { Container, Row, Col } from 'react-grid-system'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import SandstormImg from '../../../images/espresso-example-1.png'
import CoreStructureImg from '../../../images/espresso-core-structure.png'
import CoreLightImg from '../../../images/espresso-core-colors.jpg'
import CoreShapesImg from '../../../images/espresso-core-shapes.jpg'
import WebStructureImg from '../../../images/espresso-web-structure.jpg'
import WebTypographyImg from '../../../images/espresso-web-typography.jpg'
import WebTypographyNamingImg from '../../../images/espresso-web-typography-naming.jpg'
import WebFormsImg from '../../../images/espresso-web-forms.jpg'
import WebElementsImg from '../../../images/espresso-web-elements.jpg'
import SecondaryPageHeader from '../../../Components/PageHeaders/Secondary'
import EspressoPanelImage from '../../../images/espresso-panel-highlight.jpg'

function mapStateToProps(state) {
  return { page: state.pageList.trapink };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class Espresso extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <Container>
          <Row className="work__block">
            <Col md={12}>
              <SecondaryPageHeader
                  updated="10/29/2020"
                  title="Espresso Design System"
                  subtitle="Sketch Design System • 2020"
                  />
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={12} push={{md: 0}}>
              <img className="work__image" src={EspressoPanelImage} alt="Espresso Design System Preview"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={12}>
              <div className="panel problem-panel">
                <h2>The problem</h2>
                <p>It Works! just launched new website was designed and development with a third party company. The Sketch library that was delivered was a mess, with little to no structure. Developers also faced inconsistency in their components. We needed to create a 1-to-1 design system that would allow faster design and development, consistency, and accuracy.</p>
              </div>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <h2>Our design elements were everywhere...</h2>
              <p>The website was initially designed by a third party company and handed off to our teams after launch. The Sketch files were less than ideal for scaling. With each future design enhancement, the design debt would become larger and larger.</p>
              <img className="work__image" src={SandstormImg} alt="existing sketch library"/>
              <h6 className="work__caption">All text were symbols. Duplicate text styles. No nested components. Very hard to work with.</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>Evaluating our options</h2>
              <p>My team was challenged with continuous iterations of existing designs in production. Each of these one-off designs created more and more design debt. We were faced with two options:</p>
              <ol>
                <li>Import the existing library and over time make changes to get the library where we needed it.</li>
                <li>Create a new design system that is designed to house each platform</li>
              </ol>
              <p>We tried option #1 for a few design enhancement projects and it became <span className="highlight">clear to me that I needed to be pro active and create a design system</span>.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>I needed to get dev's buy-in!</h2>
              <p>The first part of my strategy was to get developers to buy-in. I first met with our lead developer and started the conversation by asking his opinion on what bothers him the most about the current codebase.</p>
              <ol>
                <li>Duplicate components. For example, the primary button alone had many duplicate components caused by different developers.</li>
                <li>Lack of style guides. Each design resulted in the developers overriding existing components to look like the designs.</li>
                <li>The amount of SASS color variables are very high due to the lack of defined colors.</li>
                <li>Other issues such as loading speed and old legacy components leftover.</li>
              </ol>
              <p>I related with him on the lack of designer and developer alignment. The problem is defining where that line exists, if at all? It is my belief that in order to ensure UI accuracy and maintain the system properly, that the UX team should design and code the design system as react components to handoff to front-end developers. This was an easy sell, and we were in agreement.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>How would we implement this?</h2>
              <p>As a team, we decided the best approach would be to first design the system completely. I will deliver components as HTML/CSS, and we will implement incrementally during the product detail redesign story by updating each component. We will follow this up by aligning our coded design system and the codebase with one dedicated sprint.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column">
              <aside className="work__aside">
                <h2>Now for the final approval.. wish me luck!</h2>
                <p>Now that I had a clear level of effort and a plan of execution, I explained how we could save significant design and development time, and how we can implement this without disrupting the current roadmap. <span className="highlight">She agreed, and I got the approval to begin working in between projects!</span></p>
              </aside>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column">
              <header className="work-header center">
                <h4 className="work-header__subtitle">design process</h4>
                <h2 className="work-header__title">Creating the design system</h2>
              </header>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column">
              <Row>
                <Col md={4}>
                  <img className="work__image" src="https://media.giphy.com/media/8yfIqHvBX2C0E/giphy.gif" alt="missed shot gif"/>
                </Col>
                <Col md={8}>
                  <div className="work__header">
                    <h3>My first shot was a miss :(</h3>
                    <h4>Create one sketch library</h4>
                  </div>
                  <p>My first idea was to create one single sketch library that would house all of the web, iOS, and Android components. This would promote consistency between platforms in key shared areas such as border-radius, colors, and icons.</p>
                  <p>The problem was that each platform needed it's own components and styles that seemed to interfere with each other. Also, the file size was getting pretty heavy for Sketch, causing my Macbook to run slow. I needed another approach.</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column">
              <Row>
                <Col md={4}>
                  <img className="work__image" src="https://media.giphy.com/media/12Tob44HkDJty8/giphy.gif" alt="made shot gif"/>
                </Col>
                <Col md={8}>
                  <div className="work__header">
                    <h3>Second shot made!</h3>
                    <h4>Parent & Children Concept</h4>
                  </div>
                  <p>I'd create a core library that would house elements that would be shared across all platforms. This would include elements like branding, shapes, icons. Next, I'd create a library for each platform that would consume Core and build upon it.</p>
                  <p><i>Fun fact: recently, Airbnb restructured their design system to follow this same concept.</i></p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="work__block" id="preview">
            <Col md={8} push={{md: 2}}>
              <div className="work__header">
                <h2>The Core Library</h2>
              </div>
              <Row>
                <Col md={8}>
                  <h4>Structure</h4>
                  <p>The core will house all components that will be shared between the child libraries. Check out the entire <a href="https://www.sketch.com/s/cb3798fc-c1e7-46b3-a9f9-3d82137ca4c0" target="_blank" rel="noopener noreferrer">Sketch file here</a>.</p>
                </Col>
                <Col md={4}>
                  <img className="work__image" src={CoreStructureImg} alt="The Structure of Core"/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={12} push={{md: 0}}>
              <img className="work__image" src={CoreLightImg} alt="The Light Theme for Core"/>
              <img className="work__image" src={CoreShapesImg} alt="The Shapes for Core"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={8} push={{md: 2}}>
              <div className="work__header">
                <h2>Web Library</h2>
              </div>
              <Row>
                <Col md={8}>
                  <h4>Atomic Design Structure</h4>
                  <p>Playing off our systems name "Espresso" we decided to change atoms, molecules, and organisms to represent growing and brewing a coffee bean.</p>
                  <ol>
                    <li>Elements: Houses our <span className="highlight">atoms</span></li>
                    <li>Components: Houses our <span className="highlight">molecules</span></li>
                    <li>Page Sections: Houses our <span className="highlight">organisms</span></li>
                  </ol>
                  <p>The plan is to add page templates as we build them out to the design system as a fourth page. Check out the entire <a href="https://www.sketch.com/s/0506d0d4-a316-4e52-ade5-1c359c49ae14" target="_blank" rel="noopener noreferrer">Sketch file here</a>.</p>
                </Col>
                <Col md={4}>
                  <img className="work__image" src={WebStructureImg} alt="The Structure of Web"/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={6} push={{md: 0}}>
              <img className="work__image" src={WebTypographyNamingImg} alt="The Typography Naming Convention for Web"/>
              <h6 className="work__caption">I played around with several naming conventions.</h6>
            </Col>
            <Col md={6} push={{md: 0}}>
              <img className="work__image" src={WebTypographyImg} alt="The Typography for Web"/>
              <h6 className="work__caption">Sketch has a limitation where alignment, weight, and color are associated with a text style. You can't see here, but each variation is stacked on top of each other to keep it clean.</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={12} push={{md: 0}}>
              <img className="work__image" src={WebFormsImg} alt="The Form Components for Web"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col md={6} push={{md: 0}}>
              <img className="work__image" src={WebFormsImg} alt="The Form Components for Web"/>
            </Col>
            <Col md={6} push={{md: 0}}>
              <img className="work__image" src={WebElementsImg} alt="The Form Components for Web"/>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(Espresso)
);
