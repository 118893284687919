import React, { Component } from 'react'

class OverviewPanel extends Component {
  render() {

    return (
      <div className="overview-panel">
        <header className="overview-panel__header">
          <span className="overview-panel__title">The Short Story</span>
        </header>
        {this.props.children}
      </div>
    )
  }
}

export default OverviewPanel
