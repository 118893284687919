import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import { Container, Row, Col } from 'react-grid-system'
import FeatureHighlight from '../../../Components/ContentBlock/FeatureHighlight'
import VideoHighlight from '../../../Components/ContentBlock/VideoHighlight'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import PreviewImg from '../../../images/connect-preview-1.png'
import OfficeImg from '../../../images/office-demo.gif'
import InsightsImg from '../../../images/connect-insights.jpg'
import TimelineWireframeImg from '../../../images/connect-timeline-wireframe-1.jpg'
import StructureWireframeImg from '../../../images/connect-structure-wireframes-1.jpg'
import FollowUpVideo from '../../../videos/connect-follow-ups.mp4'
import AddFollowUpVideo from '../../../videos/connect-add-followup-min.mp4'
import NavVideo from '../../../videos/connect-navigation-min.mp4'
import SendMessageVideo from '../../../videos/connect-send-message-min.mp4'
import ShoppingListVideo from '../../../videos/connect-shopping-list-min.mp4'
import RapidFunnel from '../../../images/rapid-funnel.png'
import PageHeader from '../../../Components/PageHeaders/Secondary'

function mapStateToProps(state) {
  return { page: state.pageList.connect };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class Connect extends Component {
  render() {
    return (
      <WorkLayout header="secondary">
        <Container>
          <PageHeader
              title="It Works! Connect"
              time="iOS & Android – 2015 - 2020"
              role="Lead UX Designer"
              featuredImage={PreviewImg}
              prototypeId="#prototype"
              >
            <p>Connect is a native mobile app that helps Distributors grow their business on-the-go. This app has evolved over the past 5 years with multiple shifts in direction. This story starts when we decided to pivot from a weekly blog format to a business growing tool.</p>
            <p>Through rebranding the app and adding core features such as sharable shopping lists, follow-ups, and messaging scripts, we were able to grow our monthly active users by 56% and our average session time by 20%. The app currently boasts <strong>over 1 million total installs</strong>.</p>
          </PageHeader>
          <Row className="work__block">
            <Col className="work__column" md={10} push={{ md: 1 }}>
              <h2>What's the problem?</h2>
              <p>The business relies on the majority of communication with customers to come directly from the Distributor's. A new Distributor just starting their business usually feels lost as to what to say or when to say it. It is awkward making your first post on social media or to your friends.</p>
              <p>Another aspect of this problem was the top leaders were also having problems keeping up with reaching out to their team personally. <span className="highlight">We needed to find a way to automate this process for both user types.</span></p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" xs={8} md={3} push={{ xs: 2, md: 1 }}>
              <img className="work__image" src={ OfficeImg } alt="office demo"/>
            </Col>
            <Col className="work__column" md={5} push={{ md: 2 }}>
              <h2>The "app within an app"</h2>
              <p>Due to the constraints we faced at the time, <span className="highlight">Connect existed as an app within the Office app</span>. If you tap the center icon it would launch into a completely different structure and visual design. You can see how this would be confusing.</p>
              <br/>
              <br/>
              <h4>The pitch for a new app</h4>
              <p>After consulting with the mobile team to get their buy-in, I pitched the idea to both Sales and Marketing. This was an easy sell, marketing saw this as an opportunity to improve known issues while launching the app on stage at the upcoming conference as a "new and improved app!".</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" xs={8} md={6} push={{ xs: 2, md: 0 }}>
              <img className="work__image" src={ InsightsImg } alt="rapid funnel"/>
            </Col>
            <Col className="work__column" md={5}>
              <h2>Insights</h2>
              <ol>
                <li><p>New Distributors find it intimidating to know how to introduce their business on social media or start a conversation with others.</p></li>
                <li><p>Some Distributors make inacurrate statements.</p></li>
                <li><p>After the first month Distributor communication would drop off. This had a negative effect on the productivity and interest of the downline.</p></li>
              </ol>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6} push={{ md: 3 }}>
              <h2>The approach</h2>
              <ol>
                <li><p>Concept a solution that solves for both new DTs and our power users.</p></li>
                <li><p>Redesign the current structure of the app to focus on the connect features at its core and include our new solution.</p></li>
              </ol>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6} push={{ md: 3 }}>
              <h2>Solution: Create a timeline!</h2>
              <p>Each instance of a new lead, Distributor, or Loyal Customer will enter that contact into a timeline of follow-ups. Each follow-up has a script attached to it.</p>
              <p>Each day simply open your follow-ups, tap message all, and tap send.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" xs={8} md={6} push={{ xs: 2, md: 0 }}>
              <img className="work__image" src={ RapidFunnel } alt="rapid funnel"/>
            </Col>
            <Col className="work__column" md={5}>
              <h2>Research</h2>
              <p>To start the design process I needed to gain insights into the current pain points of the Office app to help guide me. We gathered a total 30 Distributors of different ranks over 3 days of virtual testing over zoom. Here are a few key results:</p>
              <ol>
                <li><p>Users were unable to correctly access their contact list and messenging features. Almost all were tapping on the enrollment icon.</p></li>
                <li><p>Distributors were training their teams to use another app called RapidFunnel to set reminders to follow-up with their contacts.</p></li>
                <li><p>Distributors were using their notes app to write scripts and paste into messages, unaware the feature existed in the current app. When shown where the feature was in the app they overwhelmingly agreed they would use this feature going forward.</p></li>
              </ol>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6} push={{ md: 3 }}>
              <h2>Customer Types</h2>
              <p>One timeline would not simply work for all 3 customer types. Loyal Customers and Distributors would need the timeline to be tied around their autoship date, where Leads would be from the creation date. Also, each of the 3 customer types would need different scripts.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" xs={8} md={3} push={{ xs:2, md: 1 }}>
              <FeatureHighlight illustration="customer-type-dt" title="Distributor" text="Users who enroll other Distributors and Loyal Customers"/>
            </Col>
            <Col className="work__column" xs={8} md={3} push={{ xs:2, md: 2 }}>
              <FeatureHighlight illustration="customer-type-lc" title="Loyal Customer" text="Customers who sign with an agreement for a discount"/>
            </Col>
            <Col className="work__column" xs={8} md={3} push={{ xs:2, md: 3 }}>
              <FeatureHighlight illustration="customer-type-lead" title="Lead" text="Potential Distributors or Loyal Customers"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6} push={{ md: 3 }}>
              <h2>Defining Structure</h2>
              <img className="work__image" src={StructureWireframeImg} alt="structure wireframes"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6} push={{ md: 3 }}>
              <h2>Defining Timeline</h2>
              <p>I worked closely with a top Distributor to shape and mold the default timeline. We  needed to also allow the ability to modify a timeline for complete control.</p>
              <img className="work__image" src={TimelineWireframeImg} alt="timeline wireframes"/>
            </Col>
          </Row>
          <Row className="work__block" id="prototype">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <h2>Visual Design</h2>
              <p>The scope of this app is now fairly large. The best way for me to showcase the main features is to show snippets of the app by feature.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <VideoHighlight src={NavVideo} title="Main navigation" />
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <VideoHighlight src={FollowUpVideo} title="Completing follow-ups" />
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <VideoHighlight src={AddFollowUpVideo} title="Add new default follow-up" />
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <VideoHighlight src={SendMessageVideo} title="Send a message" />
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <VideoHighlight src={ShoppingListVideo} title="Shareable shopping list" />
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6} push={{ md: 3 }}>
              <h2>Testing & Learning</h2>
              <p>After development was completed we had one week to test and implement changes before we had to submit to the App Store in time for Conference. Testing was conducted by a designated host via zoom and was observed in another room by the entire mobile team. We found items that we could fix in time and others that are in the backlog to fix. These include:</p>
              <ol>
                <li><p>There was not a way for a contact to opt-out from communications.</p></li>
                <li><p>Onboarding was not as helpful as it should be. We believe by creating a tutorial-type onboarding experience we can help showcase the power of the app and guide new distributors into getting the ball rolling.</p></li>
                <li><p>Filtering contacts option was not easily found on Android because this was hidden in a more icon. We needed to redesign the header of the contact list.</p></li>
              </ol>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(Connect)
);
