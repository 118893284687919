import React, { Component } from 'react'

class CaratDown extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="carat-down"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 50"
      >
        <title>carat-down</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M16.23,0H1.31A1.3,1.3,0,0,0,.38,2.22L7.84,9.68a1.31,1.31,0,0,0,1.85,0l7.46-7.46A1.3,1.3,0,0,0,16.23,0Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default CaratDown;
