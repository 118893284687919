import React, { Component } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import OverviewPanel from '../../../Components/Panels/Overview'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import ExistingImage from '../../../images/product-existing-bag-selection.gif'
import PageHeader from '../../../Components/PageHeaders/Secondary'
import HeaderImg from '../../../images/product-page-panel-highlight.jpg'
import WireframeImage from '../../../images/product-page-wireframe.jpg'
import OutOfStockImg from '../../../images/product-page-oos.jpg'
import DescriptionImg from '../../../images/product-page-description.jpg'
import LowFiImage1 from '../../../images/product-page-low-fi-1.jpg'
import LowFiImage2 from '../../../images/product-page-low-fi-2.jpg'
import LowFiImageMobile from '../../../images/product-page-low-fi-mobile.jpg'
import ReviewsImage from '../../../images/product-page-reviews.jpg'
import BagGif from '../../../images/product-page-upsell.gif'
import BoughtTogetherImage from '../../../images/product-page-bought-together.jpg'
import FinalDesktopImage1 from '../../../images/product-page-final-desktop-1.jpg'
import FinalDesktopImage2 from '../../../images/product-page-final-desktop-full.jpg'
import FinalMobileImage1 from '../../../images/product-page-final-mobile.jpg'
import FinalMobileImage2 from '../../../images/product-page-final-mobile-2.jpg'

function mapStateToProps(state) {
  return { page: state.pageList.productPage };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class ProductPage extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <PageHeader
            title="It Works! Redesigned Shopping Experience"
            time="2019-2020 • UX Research & Design"
            featuredImage={HeaderImg}
            prototypeId="#prototype"
            >
            <OverviewPanel>
              For years the digital marketing team faced constraints in their ability to add promotions or make any improvements to the website to drive KPI's. As It Works! began to shift its business more towards e-commerce sales and revamped loyal customer program, I was excited to get the opportunity to improve a slow, clunky experience that is used by hundreds of thousands of monthly shoppers.
            </OverviewPanel>
          </PageHeader>
          <Container>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2>Redefining how customers shop</h2>
                </header>
                <p>Our goal for this project was to create a dynamic shopping experience that will tailor the experience to each customer type. Our ambitions were to create a strong foundation that embraced a rapidly changing business towards e-commerce rather than direct sales. Our high-level goals were to:</p>
                <ul>
                  <ol>
                    <li>Improve average order value through dynamic upsell and cross-sell opportunities</li>
                    <li>Improve backorder behavior experience</li>
                    <li>Design components that can be editable in Kentico</li>
                    <li>Improve the credibility of the products</li>
                    <li>Modernize the UI and improve overall usability</li>
                    <li>Explore possibilites of adding shipping estimates</li>
                  </ol>
                </ul>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2 className="work-header__title">Understanding the current experience</h2>
                </header>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6}>
                <img className="work__image" src={ExistingImage} alt="Current sku ux issue"/>
              </Col>
              <Col className="work__column" md={6}>
                <h3>Problem 1: Sku Management</h3>
                <p>The current codebase was not set up to allow multiple SKUs of the same product. When the business decided to add different quantity amounts, the workaround solution was to land the user at a product page and make the user select the quantity which reloads the page to the correct SKU. This is not ideal and we needed to fix that as part of the redesign.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6}>
                <img className="work__image" src={OutOfStockImg} alt="Out of stock misusing the product tag components"/>
              </Col>
              <Col className="work__column" md={6}>
                <h3>Problem 2: Backorder Behavior</h3>
                <p>The Digital Marketing team was limited in their options to display backorder behavior since it was not implemented when the new site was launched. As a workaround, the promo tag space was used and as a result, users were unable to know why the "add to cart" button was disabled.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6}>
                <img className="work__image" src={DescriptionImg} alt="The prouduct description was poorly laid out and way too much to read."/>
              </Col>
              <Col className="work__column" md={6}>
                <h3>Problem 3: Poor Communication</h3>
                <p>The current design was copy-heavy and relied on the customers to read through the description to find the benefits and features of the products. We needed to make sure that we convey this information in a way that you can understand while simply scanning the page.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8}>
                <header className="work-header">
                  <h2 className="work-header__title">Research & Learning</h2>
                </header>
                <p>Utilizing <a href="http://www.baymardinstitute.com" target="_blank" rel="noopener noreferrer">Baymard Institute Premium</a> I was able to view full case studies of existing eCommerce websites such as Wayfair, Walmart, Target, Lowes, and many more to learn best practices for designing a great e-commerce experience. I spent a lot of time understanding the many rules they have collected, reading through user studies, and identifying points of emphasis for my design. Here are a few key takeaways:</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6}>
                <p><strong>Need to Improve:</strong></p>
                <ol>
                  <li>Effectively showcase price-related special offers and discounts</li>
                  <li>Displaying price per unit when presented with options</li>
                  <li>Showing shipping estimates</li>
                  <li>Preselect the product variation</li>
                </ol>
              </Col>
              <Col className="work__column" md={6}>
                <p><strong>Keep Doing:</strong></p>
                <ol>
                  <li>Use of both bullet lists and blocks of text for product descriptions</li>
                  <li>Using 50% of the viewport for product image display</li>
                  <li>Ensuring the "buy section" only has essential information</li>
                  <li>Displaying promotional discounts near the price</li>
                  <li>High level of detail in the product descriptions</li>
                </ol>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2 className="work-header__title">Working together to generate requirements</h2>
                </header>
                <p>I met with the Web Optimization and Digital Marketing Team to discuss issues they face while managing the content. We ideated on what content we need to include in the new design. Together we generated a document with a list of items required for each use case.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6} push={{ md: 0 }}>
                <img className="work__image" src={WireframeImage} alt="Product Page Wireframes"/>
                <h6 className="work__caption">Almost all whiteboard pics I have include my shadow.</h6>
              </Col>
              <Col className="work__column" md={5}>
                <header className="work-header">
                  <h2 className="work-header__title">Designing the initial wireframes</h2>
                </header>
                <p>Next, the Digital Marketing Director and I met to lay these components onto a wireframe to get a baseline idea of how this could work and use it as a talking point during development discussions.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header center">
                  <h2 className="work-header__title">Deciding between two layouts</h2>
                  <p>For the layout we were focusing mainly on two options inspired by Nike and a combination of Walmart, Adidas, and Best Buy.</p>
                </header>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <header className="work-header">
                  <h3 className="work-header__title">Split Static Layout</h3>
                </header>
                <p>Like Nike, the right side remains static on desktop so that the product information and CTA remain in view as the users scroll the details. <span className="highlight">We decided not to go with this option mainly due to the product visually is not the selling point like Nike shoes.</span> Also, it's harder to get the information you need quickly to make an informed decision.</p>
                <img className="work__image" src={LowFiImage1} alt="Product Page Low Fi Initial Version"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <header className="work-header">
                  <h3 className="work-header__title">Final version</h3>
                </header>
                <img className="work__image" src={LowFiImage2} alt="Product Page Low Fi Final Version"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <img className="work__image" src={LowFiImageMobile} alt="Product Page Low Fi Mobile Version"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6}>
                <header className="work-header">
                  <h2 className="work-header__title">Customer Reviews</h2>
                </header>
                <p>Baymard research found in a <a href="https://baymard.com/premium/topics/457" target="_blank" rel="noopener noreferrer">study</a> that 95% of subjects relied on reviews to evaluate the product, many times using the content in reviews over the description.</p>
                <p>It was a hard sell to allow reviews because the business was worried about malicious reviews. We needed to make sure that we had a way to moderate reviews, verify purchases, and respond to negative feedback.</p>
              </Col>
              <Col className="work__column" md={6} push={{ md: 0 }}>
                <img className="work__image" src={ReviewsImage} alt="Product Page Wireframes"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6} push={{ md: 0 }}>
                <img className="work__image" src={BagGif} alt="Add Upsell to Bag Animation"/>
              </Col>
              <Col className="work__column" md={6}>
                <header className="work-header">
                  <h2 className="work-header__title">Dynamic Suggestions</h2>
                </header>
                <p>To increase average order value we've added and defined the following cross-sell opportunities in the design including:</p>
                <ol>
                  <li>Goes Together With</li>
                  <li>Recently Viewed</li>
                  <li>Frequently Bought Together</li>
                  <li>Compare Similar Items</li>
                </ol>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <header className="work-header">
                  <h2 className="work-header__title">Testing Cross-sell</h2>
                </header>
                <p>Marketing wanted to implement a "bought together" into the current design. This was a great opportunity for us to test placement, usability, and effectiveness. This simple addition was responsible for adding millions in additional revenue.</p>
                <img src={BoughtTogetherImage} alt="Frequently Bought Together"/>
              </Col>
            </Row>
            <Row className="work__block" id="prototype">
              <Col className="work__column">
                <header className="work-header center">
                  <h2 className="work-header__title">A new convienient shopping experience</h2>
                </header>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <img className="work__image" src={FinalDesktopImage1} alt="Final Design Desktop 1"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <img className="work__image" src={FinalDesktopImage2} alt="Final Design Desktop Full"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <img className="work__image" src={FinalMobileImage1} alt="Final Design Mobile 1"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <img className="work__image" src={FinalMobileImage2} alt="Final Design Mobile 2"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <aside className="work__aside">
                  <header className="work-header center">
                    <h2 className="work-header__title">What were the results?</h2>
                  </header>
                  <p>I would love to tell you that this increased business by x%, however, this is currently in the roadmap for implementation. I can say this design was successful in that we designed for tons of flexibility in Kentico, we've proven the cross-sell strategy will work and found where it works best, we've added backorder behavior, took steps to improve credibility, and implemented the design system components for consistency.</p>
                </aside>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column">
                <header className="work-header center">
                  <h2 className="work-header__title">What have I learned?</h2>
                </header>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <p><strong>1. Find a way to test</strong></p>
                <p>One major constraint I faced throughout my journey at It Works! was the lack of ability to test my designs. This was mostly due to the surprise factor that the company relies on. Every new feature of all of our products was a big reveal. Looking back, I could have tested other ways through either paid services or guerrilla testing without the branding.</p>
              </Col>
              <Col className="work__column" md={8} push={{ md: 2 }}>
                <p><strong>2. Early collaboration through workshops</strong></p>
                <p>This project happened right before I advocated for design sprints and workshops, and I can see many opportunities that I could have brought different departments together to collaborate by replacing meetings.</p>
              </Col>
            </Row>
            <Row className="work__block work__conclusion">
              <Col xs={8} push={{xs: 2}}>
                <Rating {...this.props} />
              </Col>
            </Row>
          </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(ProductPage)
);
