import React, { Component } from 'react'

class QuestionList extends Component {
  render () {
    return (
      <ul className="question-list">
        {this.props.children}
      </ul>
    )
  }
}

export default QuestionList;
