import React, { Component } from 'react'

class CustomerTypeLead extends Component {
  render() {
    const styles = [
      {fill: "#f9e734"},
      {fill: "#f9e734"},
      {fill: "#ffdfba"},
      {fill: "#fe4f60"},
      {fill: "#ffebd2"},
      {fill: "#42434d"},
      {fill: "#ff6d7a"},
      {fill: "#fff3e4"},
      {fill: "#4d4e59"},
      {fill: "#2c2c33"},
      {fill: "#d6f4fc"},
      {fill: "#bdeffc"},
      {fill: "#ffd6a6"}
    ]

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="customer-type-lead"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
      >
        <title>lead</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path style={styles[1]} d="M25,0A25,25,0,0,0,10.51,45.37a7.58,7.58,0,0,1,.65-2.09L9.39,41.51a3.11,3.11,0,0,1-.18-4.2,1.63,1.63,0,0,0-.16-2.22,3.12,3.12,0,0,1-.3-4.2l1-1.31a1.62,1.62,0,0,0,.29-1.5l-.67-2.29A3.09,3.09,0,0,1,11.21,22l.89-.37a1.63,1.63,0,0,0,1-1.53V17.81A9.82,9.82,0,0,1,22.93,8h4.36a9.82,9.82,0,0,1,9.81,9.8v2.34a1.64,1.64,0,0,0,1,1.53L39,22a3.1,3.1,0,0,1,1.8,3.75l-.68,2.29a1.68,1.68,0,0,0,.29,1.5l1,1.31a3.13,3.13,0,0,1-.3,4.2A1.64,1.64,0,0,0,41,37.31a3.1,3.1,0,0,1-.18,4.2l-1.76,1.77a7.32,7.32,0,0,1,.62,1.94A25,25,0,0,0,25,0Z"/>
            <path style={styles[2]} d="M28.57,36.14h0a3.13,3.13,0,0,0,.06.6Q28.59,36.44,28.57,36.14Z"/>
            <path style={styles[2]} d="M28.49,38.36a.72.72,0,0,1,.24-1s.05,0,.08,0a4.32,4.32,0,0,1-.16-.51s0,.05,0,.08a9,9,0,0,1-6.43.24,4.48,4.48,0,0,1-1.53,2.38l4.4,3.41,2.59-2a.72.72,0,0,1,1,.13L30.17,40A4.41,4.41,0,0,1,28.49,38.36Z"/>
            <path style={styles[3]} d="M40.18,34a1.66,1.66,0,0,0,.16-2.23l-1-1.32a3.09,3.09,0,0,1-.55-2.81l.67-2.29a1.65,1.65,0,0,0-1-2L37.57,23a3.1,3.1,0,0,1-1.93-2.88V17.81a8.35,8.35,0,0,0-4.46-7.39,1.09,1.09,0,0,1-1.11,1H25.66a9.07,9.07,0,0,0-9.08,9.08v2.34a2.32,2.32,0,0,1-.48,1.4,17.68,17.68,0,0,0,12.7-6.91.73.73,0,0,1,1.25.18,6.48,6.48,0,0,0,4.25,3.83.72.72,0,0,1,.52.65c0,.06,0,5.1,0,5.13a9.74,9.74,0,0,1-6.26,9q0,.3.06.6s0,.06,0,.09a4.32,4.32,0,0,0,.16.51.71.71,0,0,1,.92.27,3,3,0,0,0,2.44,1.45.62.62,0,0,1,.27,0A7.43,7.43,0,0,1,38.28,42l1.53-1.52a1.66,1.66,0,0,0,.13-2.2A3.08,3.08,0,0,1,40.18,34Z"/>
            <path style={styles[3]} d="M15.49,28.41a.72.72,0,0,1,.62-.81s0,0,.05,0c0-.18,0-.35,0-.52h0V25.73c-.23,0-.46.05-.69.06a.7.7,0,0,1-.72-.56l-.5.2a2.38,2.38,0,0,0-1.38,2.88l.67,2.29a2.37,2.37,0,0,1-.41,2.15L12,34.07a2.38,2.38,0,0,0,.23,3.21,2.38,2.38,0,0,1,.23,3.21A2.51,2.51,0,0,0,12,42a7.48,7.48,0,0,1,5.95-2.93,3,3,0,0,0,3-3v-.19A9.71,9.71,0,0,1,15.49,28.41Z"/>
            <path style={styles[4]} d="M16.12,27.08c0,.17,0,.34,0,.52h0c0-.18,0-.35,0-.52Z"/>
            <path style={styles[4]} d="M29.23,19.15a19.21,19.21,0,0,1-10.42,6.19,1.12,1.12,0,0,1,.08.26,9.26,9.26,0,0,0,4.18,7.29,1.42,1.42,0,0,1,.23,2.24,8.37,8.37,0,0,0,1.81.21,8.27,8.27,0,0,0,8.26-8.26V22.6A8,8,0,0,1,29.23,19.15Z"/>
            <path style={styles[5]} d="M32.41,42l-2.14,7.46a24.79,24.79,0,0,0,6.36-2.31A6.79,6.79,0,0,0,32.41,42Z"/>
            <path style={styles[5]} d="M18.71,42.45l-.2-.69a6.74,6.74,0,0,0-4.93,5.48A25.35,25.35,0,0,0,20,49.5l-1.74-6.17A.73.73,0,0,1,18.71,42.45Z"/>
            <path style={styles[6]} d="M27.29,9.45H22.93a8.37,8.37,0,0,0-8.35,8.36v2.33A3.1,3.1,0,0,1,12.65,23l-.89.36a1.65,1.65,0,0,0-1,2l.68,2.29a3.12,3.12,0,0,1-.55,2.81l-1,1.32A1.66,1.66,0,0,0,10,34a3.09,3.09,0,0,1,.25,4.26,1.65,1.65,0,0,0,.13,2.2L11.94,42h0a2.51,2.51,0,0,1,.55-1.51,2.38,2.38,0,0,0-.23-3.21A2.38,2.38,0,0,1,12,34.07l1.05-1.32a2.37,2.37,0,0,0,.41-2.15l-.67-2.29a2.38,2.38,0,0,1,1.38-2.88l.5-.2a.33.33,0,0,1,0-.13.74.74,0,0,1,.7-.76c.25,0,.49-.05.73-.07a2.32,2.32,0,0,0,.48-1.4V20.53a9.07,9.07,0,0,1,9.08-9.08h4.41a1.09,1.09,0,0,0,1.11-1A8.21,8.21,0,0,0,27.29,9.45Z"/>
            <path style={styles[7]} d="M23.07,32.89a9.26,9.26,0,0,1-4.18-7.29,1.12,1.12,0,0,0-.08-.26,20,20,0,0,1-2.69.39v1.35c0,.17,0,.34,0,.52a.73.73,0,0,1,.77.62,8.29,8.29,0,0,0,6.37,6.91A1.42,1.42,0,0,0,23.07,32.89Z"/>
            <path style={styles[8]} d="M18.15,40.51H17.9a6,6,0,0,0-6,5.76q.84.51,1.71,1a6.74,6.74,0,0,1,4.93-5.48Z"/>
            <path style={styles[9]} d="M32.82,40.54,32.41,42a6.79,6.79,0,0,1,4.22,5.15c.59-.31,1.16-.64,1.72-1A6,6,0,0,0,32.82,40.54Z"/>
            <path style={styles[10]} d="M24.39,44.22l-3.71-2.87L20.22,41a.94.94,0,0,1-.77.57,7.44,7.44,0,0,0-.94.19l-.36-1.24h0l.36,1.25.2.69h0a.72.72,0,0,1,.9.5l1.92,6.82a25.41,25.41,0,0,0,3.16.23.7.7,0,0,1-.33-.59Z"/>
            <path style={styles[10]} d="M30.78,41.56a1,1,0,0,1-.59-1.6h0l-1.45,1.12h0a.72.72,0,0,1-.13,1l-2.75,2.13V49.4a.71.71,0,0,1-.32.59,25.09,25.09,0,0,0,3.15-.26L31,41.61h0Z"/>
            <path style={styles[11]} d="M18.15,40.51l.36,1.24a7.44,7.44,0,0,1,.94-.19.94.94,0,0,0,.77-.57l-.91-.7A4.16,4.16,0,0,1,18.15,40.51Z"/>
            <path style={styles[11]} d="M30.19,40a1,1,0,0,0,.59,1.6l.23,0,.34-1.19A4.43,4.43,0,0,1,30.19,40Z"/>
            <path style={styles[12]} d="M28.65,36.83s0-.06,0-.09a3.13,3.13,0,0,1-.06-.6,9.62,9.62,0,0,1-6.2.26,4.91,4.91,0,0,1-.13.75,9,9,0,0,0,6.43-.24S28.66,36.86,28.65,36.83Z"/>
            <path d="M41.18,35.09a3.13,3.13,0,0,0,.3-4.2l-1-1.31a1.68,1.68,0,0,1-.29-1.5l.68-2.29A3.1,3.1,0,0,0,39,22l-.9-.37a1.64,1.64,0,0,1-1-1.53V17.8A9.82,9.82,0,0,0,27.29,8H22.93a9.82,9.82,0,0,0-9.8,9.81v2.33a1.63,1.63,0,0,1-1,1.53l-.89.37a3.09,3.09,0,0,0-1.8,3.75l.67,2.29a1.62,1.62,0,0,1-.29,1.5l-1,1.31a3.12,3.12,0,0,0,.3,4.2,1.63,1.63,0,0,1,.16,2.22,3.11,3.11,0,0,0,.18,4.2l1.77,1.77a7.58,7.58,0,0,0-.65,2.09l.66.46h0l.69.45a6,6,0,0,1,6-5.76h.25a4.16,4.16,0,0,0,1.16-.22l.91.7.46.36,3.71,2.87V49.4a.7.7,0,0,0,.33.59h.8a.71.71,0,0,0,.32-.59V44.22l2.75-2.13a.72.72,0,0,0,.13-1h0a.72.72,0,0,0-1-.13l-2.59,2-4.4-3.41a4.48,4.48,0,0,0,1.53-2.38,4.91,4.91,0,0,0,.13-.75,9.62,9.62,0,0,0,6.2-.26h0a9.74,9.74,0,0,0,6.26-9s0-5.07,0-5.13a.72.72,0,0,0-.52-.65,6.48,6.48,0,0,1-4.25-3.83.73.73,0,0,0-1.25-.18,17.68,17.68,0,0,1-12.7,6.91c-.24,0-.48.06-.73.07a.74.74,0,0,0-.7.76.33.33,0,0,0,0,.13.7.7,0,0,0,.72.56c.23,0,.46,0,.69-.06a20,20,0,0,0,2.69-.39,19.21,19.21,0,0,0,10.42-6.19,8,8,0,0,0,4.14,3.45v4.48a8.27,8.27,0,0,1-8.26,8.26,8.37,8.37,0,0,1-1.81-.21,8.29,8.29,0,0,1-6.37-6.91.73.73,0,0,0-.77-.62h0s0,0-.05,0a.72.72,0,0,0-.62.81,9.71,9.71,0,0,0,5.45,7.44V36a3,3,0,0,1-3,3A7.48,7.48,0,0,0,12,42h0l-1.52-1.52a1.65,1.65,0,0,1-.13-2.2A3.09,3.09,0,0,0,10,34a1.66,1.66,0,0,1-.16-2.23l1-1.32a3.12,3.12,0,0,0,.55-2.81l-.68-2.29a1.65,1.65,0,0,1,1-2l.89-.36a3.1,3.1,0,0,0,1.93-2.88V17.81a8.37,8.37,0,0,1,8.35-8.36h4.36a8.21,8.21,0,0,1,3.89,1,8.35,8.35,0,0,1,4.46,7.39v2.33A3.1,3.1,0,0,0,37.57,23l.89.36a1.65,1.65,0,0,1,1,2l-.67,2.29a3.09,3.09,0,0,0,.55,2.81l1,1.32A1.66,1.66,0,0,1,40.18,34a3.08,3.08,0,0,0-.24,4.26,1.66,1.66,0,0,1-.13,2.2L38.28,42a7.43,7.43,0,0,0-5.84-2.94.62.62,0,0,0-.27,0,3,3,0,0,1-2.44-1.45.71.71,0,0,0-.92-.27s-.06,0-.08,0a.72.72,0,0,0-.24,1A4.41,4.41,0,0,0,30.17,40h0a4.43,4.43,0,0,0,1.16.45L31,41.6h0l-2.33,8.12.8-.14c.27,0,.53-.09.8-.15L32.41,42l.41-1.44a6,6,0,0,1,5.53,5.59l.68-.44h0l.65-.47a7.32,7.32,0,0,0-.62-1.94l1.76-1.77a3.1,3.1,0,0,0,.18-4.2A1.64,1.64,0,0,1,41.18,35.09Z"/>
            <path d="M18.74,42.44h0a.73.73,0,0,0-.47.88L20,49.5l.79.14.79.12-1.92-6.82A.72.72,0,0,0,18.74,42.44Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default CustomerTypeLead;
