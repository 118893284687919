import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from '../Buttons/Primary'
import Icon from '../../icons'
import Logo from '../logos'

class NavPrimary extends React.Component {
  constructor() {
    super();

    this.state = {
      menuIsActive: false
    }
  }

  handleMenuClick() {
    const _className = "menu-is-active";
    const _menuIsActive = this.state.menuIsActive;

    if (_menuIsActive) {
      document.body.classList.remove(_className);
    } else {
      document.body.classList.add(_className);
    }

    this.setState({
      menuIsActive: !_menuIsActive
    })
  }

  handleNavClick() {
    if (this.state.menuIsActive) {
      document.body.classList.remove('menu-is-active');

      this.setState({
        menuIsActive: false
      })
    }
  }

  render() {
    return (
      <div className="site-nav__wrapper">
        <nav className="site-nav nav-slide-left" onClick={ this.handleNavClick.bind(this) }>
          <div className="site-nav__header"><NavLink className="site-nav__logo" to="/"><Logo name="drew" /></NavLink></div>
          <nav className="site-nav__utility">
            <a href="/coaching" className="site-nav__link"><Icon className="site-nav__icon" name="computer" />1:1 Coaching</a>
            <a href="/bio" className="site-nav__link"><Icon className="site-nav__icon" name="user" />about me</a>
          </nav>
          <Button chevron type="ghost" className="site-nav__button" to="https://www.itsmedrew.com/resume/drew-roberts_resume.pdf" external="true">Resume</Button>
          <div className="site-nav__button-group">
            <a className="site-nav__icon-link" href="https://www.linkedin.com/in/itsmedrew/" target="_blank" rel="noopener noreferrer"><Icon name="linkedin" /></a>
            <a className="site-nav__icon-link" href="https://www.dribbble.com/itsmedrew/" target="_blank" rel="noopener noreferrer"><Icon name="dribbble" /></a>
            <a className="site-nav__icon-link" href="https://www.youtube.com/@Monkeybehindthekeyboard" target="_blank" rel="noopener noreferrer"><Icon name="youtube" /></a>
          </div>
        </nav>
        <button className="menu" onClick={ this.handleMenuClick.bind(this) }>
          <div className="menu-hamburger__wrapper">
            <div className="menu-hamburger"><span></span></div>
          </div>
        </button>
      </div>
    );
  }
}

export default NavPrimary;
