import React, { Component } from 'react'

class Linkedin extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="linkedin"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>linkedin</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M24,0H0V24H24ZM23,23H1V1H23Z"/>
            <path d="M7.23,8.3A1.2,1.2,0,0,0,8.46,7.15,1.23,1.23,0,0,0,6,7.15,1.19,1.19,0,0,0,7.23,8.3Z"/>
            <rect x="6.09" y="9.35" width="2.27" height="8.18"/>
            <path d="M12.44,12.8A1.51,1.51,0,0,1,14,11.13a1.41,1.41,0,0,1,1.46,1.6v4.8h2.26V12.32a2.76,2.76,0,0,0-2.82-3.08,2.48,2.48,0,0,0-2.46,1.55h-.1V9.35H10.17v8.18h2.27Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default Linkedin;
