import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../../store/actions'
import { Container, Row, Col } from 'react-grid-system'
import Rating from '../../../../Components/Rating'
import WorkLayout from '../../../../layouts/work'
import HeaderImg from '../../../../images/palz-header.jpg'
import PageHeader from '../../../../Components/PageHeaders/Secondary'
import FoodImg from '../../../../images/palz-food.jpg'
import InsideImg from '../../../../images/palz-inside.jpg'
import MenuImg1 from '../../../../images/palz-menu-1.jpg'
import MenuImg2 from '../../../../images/palz-menu-2.jpg'

function mapStateToProps(state) {
  return { page: state.pageList.doordash };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class PalzTapHouse extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <PageHeader
            featuredImage={HeaderImg}
            title="Palz Tap House Branding"
            time="2013"
            role="Graphic Designer"
            >
            <p>I've decided to add this project because of how neat it is to have created something that thousands of people see over the past 8 years and still holds up to this day.</p>
            <p>
            I was approached with a project through a referral to design the branding for a chain of restaurants opening soon. The direction they wanted to go with was an old-style distillery combined with an up-scale wine look. The main requirements I had was that it had to include the state since there were plans to expand and the tagline "Food, Cocktails, Entertainment".
            </p>
          </PageHeader>
        <Container>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <img src={InsideImg} alt=""/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <img src={MenuImg1} alt=""/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <img src={MenuImg2} alt=""/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <img src={FoodImg} alt=""/>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(PalzTapHouse)
);
