import { databaseRef } from '../../firebase';

import {
  FETCH_FEEDBACK_REQUEST,
  FETCH_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_ERROR
} from '../constants';

function fetchFeedbackListener() {
  return (dispatch, getState) => {
    const currentFeedback = getState().feedback;
    dispatch({
      type: FETCH_FEEDBACK_REQUEST,
      payload: currentFeedback
    });
    databaseRef.on('value', snapshot => {
      dispatch({
        type: FETCH_FEEDBACK_SUCCESS,
        payload: snapshot.val()
      });
    })
  };
}

function addFeedback(page, rating, comment) {
  return (dispatch, getState) => {
    const _page = page || ''
    const _rating = rating || ''
    const _comment = comment || ''
    const _newFeedbackRef = databaseRef.child('/' + _page + '/feedback/').push()

    _newFeedbackRef.set({
      rating: _rating,
      comment: _comment,
      dateAdded: Date()
    }).catch(function(error) {
      dispatch({
        type: ADD_FEEDBACK_ERROR
      });
      console.log('Add Feedback failed: ', error.message);
    })
  }
}

export {
  fetchFeedbackListener,
  addFeedback
};
