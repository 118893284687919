import React, { Component } from 'react'

class Computer extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="computer"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 18"
      >
        <title>computer</title>
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="m4,18v-2h5v-3H2c-.55,0-1.02-.2-1.41-.59-.39-.39-.59-.86-.59-1.41V2C0,1.45.2.98.59.59c.39-.39.86-.59,1.41-.59h16c.55,0,1.02.2,1.41.59.39.39.59.86.59,1.41v9c0,.55-.2,1.02-.59,1.41-.39.39-.86.59-1.41.59h-7v3h5v2H4Zm-2-7h16V2H2v9Z"/>
        </g>
      </svg>
    )
  }
}

export default Computer;
