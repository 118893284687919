import React, { Component } from 'react'

class FormSuccess extends Component {
  render() {
    return (
      <div className="form-success">
        <h3 className="form-success__title">Success!</h3>
        <p className="form-success__message">A member of our team will contact you by the next business day to help you schedule your appointment.</p>
      </div>
    );
  }
}

export default FormSuccess;
