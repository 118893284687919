import React, { Component } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import WorkLayout from '../../layouts/work'
import BioImage from '../../images/bio-drew.jpg'
import BjjImage from '../../images/bio-bjj.jpg'
import Loading from '../../Components/Loading'
import Quote from '../../Components/ContentBlock/Quote'
import JLPTLogo from '../../images/jlpt-logo.jpg'
import NicholeProfile from '../../images/nichole-profile.jpeg'
import MentorCallImage from '../../images/bio-mentor-example.jpg'
import JapaneseImage from '../../images/bio-japanese.jpg'

class Bio extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true })
    }, 1000)
  }

  render() {

    if (!this.state.loaded) { return <Loading /> }

    return (
      <WorkLayout header="secondary">
        <div className="bio">
          <Container>
            <Row className="bio__row">
              <Col md={5}>
                <img className="bio__image" src={ BioImage } alt="a portrait shot of me"/>
              </Col>
              <Col md={7} className="bio__about">
                <h2>Hello, I'm Drew.</h2>
                <br/>
                <h3>Ever heard of Huntington, W.V.?</h3>
                <p>You may have heard of Marshall University, the Mothman, or perhaps seen the documentary about the drug crisis. Luckily my parents moved my family to Florida at a young age.</p>
                <p>With the lack of opportunities (and sights of abandoned buildings and torn roads) the town I called home, gives me the drive to be the best version of myself. For this, I'm grateful for my small-town beginnings.</p>
              </Col>
            </Row>
            <Row className="bio__row">
              <Col>
                <Quote source="Nichole - Amazon - Sr. Director, Digital CX, Lifecycle & Loyalty" image={NicholeProfile}>
                  "Drew has a natural gift for delivering this guidance in a respectful and productive way. I had the chance to watch Drew really refine his ability to guide stakeholders down the best path, even when it wasn’t their original path."
                </Quote>
              </Col>
            </Row>
            <Row className="bio__row">
              <Col md={6}>
                <h2>Proud Mentor of 30+ graduates</h2>
                <p>In addition to my professional work, I am passionate about giving back to the community through mentoring students at <a href="http://www.careerfoundry.com" target="_blank" rel="noopener noreferrer">CareerFoundry</a>, an online bootcamp that offers specialized programs in UX design, UI design, and data analytics.</p>
              </Col>
              <Col md={4} push={{ md: 1 }}>
                <img className="bio__image" src={ MentorCallImage } alt="receiving my stripe in jiu-jitsu"/>
              </Col>
            </Row>
            <Row className="bio__row">
              <Col md={6}>
                <h2>Pushing the boundaries of what I can achieve on and off the mats<span role="img" aria-label="flexed bicep"> 💪🏻</span></h2>
                <p>I began my Jiu-Jitsu journey in 2020 with a goal to sharpen my mind, focus, and push myself past my limits. Plus, we all could use less screen time.</p>
                <ul>
                  <li>A journey of extreme focus, consistently pursuing what I'm passionate about.</li>
                  <li>Driven by deep gratitude, I am compelled to give back and help others.</li>
                  <li>Confidence to leap into the unknown.</li>
                </ul>
              </Col>
              <Col md={4} push={{ md: 1 }}>
                <img className="bio__image" src={ BjjImage } alt="receiving my stripe in jiu-jitsu"/>
              </Col>
            </Row>
            <Row className="bio__row">
              <Col md={6}>
                <h2>N4 Level Japanese</h2>
                <p>For the past 2 years, I've been through intense daily study with a professional Japanese teacher and a tutor. I am motivated by the awesome people I've met through this journey and the benefits that transfer into my work:</p>
                <ul>
                  <li>Understanding Context</li>
                  <li>Decoding Complexity</li>
                  <li>Understanding Culture</li>
                  <li>Layered Communication</li>
                  <li>Structured Learning Methods</li>
                </ul>
                <img width="180px" src={JLPTLogo} alt=""/>
              </Col>
              <Col md={4} push={{ md: 1 }}>
                <img className="bio__image" src={ JapaneseImage } alt="receiving my stripe in jiu-jitsu"/>
              </Col>
            </Row>
          </Container>
        </div>
      </WorkLayout>
    );
  }
}

export default Bio;
