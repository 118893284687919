import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import PrimaryHeader from '../../Components/Header/Primary'
import SecondaryHeader from '../../Components/Header/Secondary'
import Footer from '../../Components/Footer'
import Loading from '../../Components/Loading'

class WorkLayout extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false
    }
  }

  renderHeader = (header) => {
    let el

    if (header === 'primary') { el = <PrimaryHeader /> }
    if (header === 'secondary') { el = <SecondaryHeader /> }

    return el;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true })
    }, 500)
  }

  render() {
    const { header, className } = this.props

    if (!this.state.loaded) { return <Loading /> }

    return (
      <div className={classnames('tpl-work', className)}>
        {header ? this.renderHeader(header) : null}
        <div className="tpl-work__content">
          {this.props.children}
        </div>
        <Footer />
     </div>
    );
  }
}

WorkLayout.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string
}

export default WorkLayout;
