import React from 'react';
import Cloud from './cloud';
import Moon from './moon';
import CustomerTypeLead from './customer-type-lead';
import CustomerTypeDt from './customer-type-dt';
import CustomerTypeLc from './customer-type-lc';

const Illustration = props => {
  switch(props.name) {
    case "cloud":
      return <Cloud {...props} />;
    case "moon":
      return <Moon {...props} />;
    case "customer-type-lead":
      return <CustomerTypeLead {...props} />;
    case "customer-type-dt":
      return <CustomerTypeDt {...props} />;
    case "customer-type-lc":
      return <CustomerTypeLc {...props} />;
    default:
      return <div />;
  }
}
export default Illustration;
