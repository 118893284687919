import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Logo from '../../../logos'
import Icon from '../../../../icons'

class CaseStudyPanel extends Component {
  render() {
    const { image, title, text, logo, link, color, year, backgroundImage, deck } = this.props
    let logoEl

    if (logo) { logoEl = logo }

    const headerStyle = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {};

    const checkTarget = deck ? '_blank' : false;

    let pdfEl
    let callToAction

    if (deck) {
      pdfEl = <div className="tag"><Icon className="tag__icon" name="pdf" />PDF</div>
    }

    if (deck) {
      callToAction = <span className="cs-panel-primary__cta">View PDF Slide Deck →</span>
    } else {
      callToAction = <span className="cs-panel-primary__cta link">View Case Study</span>
    }

    return (
      <a className="cs-panel-primary" href={link} target={checkTarget} >
        <header className={classnames("cs-panel-primary__header", color)} style={headerStyle}>
          <div className="cs-panel-primary__logo"><Logo name={logoEl} /></div>
          <img className="cs-panel-primary__image" src={image} alt={title} />
        </header>
        <div className="cs-panel-primary__content">
          <span className="cs-panel-primary__year">{year}</span>
          <h2 className="cs-panel-primary__title">{title}</h2>
          {pdfEl}
          <p className="cs-panel-primary__text">{text}</p>
          {callToAction}
        </div>
      </a>
    )
  }
}

CaseStudyPanel.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  deck: PropTypes.bool,
  backgroundImage: PropTypes.string
}

CaseStudyPanel.defaultProps = {
  deck: false
}

export default CaseStudyPanel
