import React, { Component } from 'react'
import Nav from '../../Nav'

class PrimaryHeader extends Component {
  render() {
    return (
      <header className="primary-header">
        <Nav />
      </header>
    );
  }
}

export default PrimaryHeader;
