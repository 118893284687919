import React, { Component } from 'react'

class Moon extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="moon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14.56 16.1"
      >
        <title>moon</title>
        <path d="M14.2,12h0l-.41.16-.21.07-.1,0-.1,0-.41.11-.2.05-.19,0A6.24,6.24,0,0,1,11,12.6a6.56,6.56,0,0,1-1.37-.19A5.16,5.16,0,0,1,8.48,12l-.26-.14-.13-.08A.42.42,0,0,1,8,11.66c-.16-.1-.31-.22-.46-.33a4.92,4.92,0,0,1-.73-.79A4.36,4.36,0,0,1,6,8.75a3.62,3.62,0,0,1-.06-.46V7.83a3.57,3.57,0,0,1,0-.47,2,2,0,0,1,0-.24l0-.23c0-.15.07-.31.11-.46l0-.12,0,0,0-.06,0-.12a.28.28,0,0,0,0-.11,3.74,3.74,0,0,1,.22-.45c.07-.15.16-.3.25-.46A6.3,6.3,0,0,1,8.4,3.33a6.8,6.8,0,0,1,1.29-.72l.38-.16.41-.14c.29-.1.58-.17.9-.26h0A.24.24,0,0,0,11.51,2a.27.27,0,0,0,0-.39,4,4,0,0,0-.8-.68L10.28.64,10,.53,9.79.43,9.53.33,9.27.25A5.47,5.47,0,0,0,8.72.12,8.31,8.31,0,0,0,7.59,0,7.26,7.26,0,0,0,3.16,1.43,7.66,7.66,0,0,0,.36,5.35,8,8,0,0,0,0,7.77,8.25,8.25,0,0,0,.1,9v.15l0,.15c0,.11,0,.2.06.3s.1.4.17.6l.09.29.11.29.12.28c0,.09.08.19.13.28a7.56,7.56,0,0,0,.61,1.07,8.65,8.65,0,0,0,.76,1l.21.22.22.22.46.4c.15.13.32.25.49.37l.25.17a2.26,2.26,0,0,0,.26.16l.26.15.27.15.27.13.28.12a5.37,5.37,0,0,0,.56.21c.2.07.38.12.58.17a8,8,0,0,0,1.17.22A7.67,7.67,0,0,0,9.77,16a7.25,7.25,0,0,0,2.08-.74,6,6,0,0,0,1.66-1.28c.05-.06.11-.12.16-.19l.16-.19.15-.2.13-.21a4.4,4.4,0,0,0,.43-.87.28.28,0,0,0,0-.18A.26.26,0,0,0,14.2,12Z"/>
      </svg>
    )
  }
}

export default Moon;
