import React, { Component } from 'react'

class Footer extends Component {
  render() {
    const year = (new Date()).getFullYear();

    return (
      <footer className="site-footer container container--grid">
        <span className="site-footer__copyright">
          { year }. Designed with ☕️ by Drew. Developed with React js.
        </span>
      </footer>
    );
  }
}

export default Footer;
