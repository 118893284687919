import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FAQItem from './Item';
import { Accordion } from 'react-accessible-accordion';

class FAQ extends Component {
  render() {
    const { faqs } = this.props;

    return (
      <Accordion>
        <h2 className="accordion__title">FAQ</h2>
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </Accordion>
    )
  }
}

FAQ.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })
  ).isRequired,
  className: PropTypes.string
}

export default FAQ;
