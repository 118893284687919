import React, { Component } from 'react'
import classnames from 'classnames'
import Nav from '../../Nav'
import { NavLink } from 'react-router-dom'
import Logo from '../../logos'

class SecondaryHeader extends Component {
  constructor() {
    super();

    this.handleScroll = this.handleScroll.bind(this)

    this.state = {
      hasScrolled: false,
      scrollingUp: false,
      scrollTop: 0,
      height: 0
    }
  }

  componentDidMount() {
    const height = this.divElement.clientHeight

    window.addEventListener('scroll', this.handleScroll)

    this.setState({ height })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const { height, scrollTop } = this.state
    const scrollPosition = window.pageYOffset | document.body.scrollTop
    const isOutsideNav = scrollPosition >= height + 100

    if (!isOutsideNav) { this.setState({ hasScrolled: false })}

    if (scrollPosition < scrollTop) {
      this.setState({ hasScrolled: true, scrollingUp: true })
    } else {
      this.setState({ scrollingUp: false })
    }

    this.setState({ scrollTop: scrollPosition })
  }

  render() {
    const { hasScrolled, scrollingUp } = this.state

    return (
      <header
          className={classnames("secondary-header", {"nav-down": scrollingUp}, {"nav-up": !scrollingUp && hasScrolled})}
          ref={ (divElement) => { this.divElement = divElement } }
        >
        <NavLink className="secondary-header__logo" to="/"><Logo name="drew" /></NavLink>
        <Nav />
      </header>
    );
  }
}

export default SecondaryHeader;
