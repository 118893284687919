import React, { Component } from 'react'

class LogoWalmart extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      	 viewBox="0 0 176.73 42.68">
         <g id="Layer_1-2" data-name="Layer 1">
           <g>
             <g>
               <path className="walmart__logo-1" d="M104.85,24.61c0-4.22-1.8-7.93-7.89-7.93-3.12,0-5.6,.88-6.96,1.66l.99,3.39c1.24-.78,3.21-1.43,5.07-1.43,3.09,0,3.59,1.75,3.59,2.87v.27c-6.73-.01-10.98,2.32-10.98,7.07,0,2.9,2.17,5.62,5.93,5.62,2.32,0,4.25-.92,5.41-2.4h.12s.77,3.21,5,1.99c-.22-1.34-.29-2.77-.29-4.48v-6.62Zm-5.02,4.45c0,.34-.03,.7-.12,1.01-.39,1.29-1.73,2.38-3.4,2.38-1.39,0-2.5-.79-2.5-2.46,0-2.56,2.82-3.26,6.02-3.25v2.32Z"/>
               <path className="walmart__logo-1" d="M26.71,10.94l-2.23,10.71c-.6,3.12-1.14,5.55-1.57,8.41h-.07c-.38-2.82-.87-5.16-1.48-8.2l-2.33-10.91h-5.51l-2.49,10.64c-.71,3.23-1.36,5.84-1.78,8.6h-.08c-.43-2.59-1-5.87-1.62-9,0,0-1.48-7.61-2-10.23H0S4.4,28.96,5.1,31.89c.82,3.41,2.3,4.67,6.55,3.82l2.74-11.17c.7-2.78,1.16-4.75,1.61-7.58h.08c.31,2.85,.76,4.81,1.33,7.59,0,0,1.12,5.07,1.69,7.74,.57,2.66,2.17,4.34,6.34,3.43l6.54-24.77h-5.28Z"/>
               <path className="walmart__logo-1" d="M47,24.61c0-4.22-1.8-7.93-7.89-7.93-3.13,0-5.61,.88-6.96,1.66l.99,3.39c1.24-.78,3.21-1.43,5.07-1.43,3.09,0,3.59,1.75,3.59,2.87v.27c-6.73-.01-10.98,2.32-10.98,7.07,0,2.9,2.17,5.62,5.93,5.62,2.32,0,4.25-.92,5.41-2.4h.11s.77,3.21,5.01,1.99c-.22-1.34-.3-2.77-.3-4.48v-6.62Zm-5.02,4.45c0,.34-.03,.7-.12,1.01-.39,1.29-1.72,2.38-3.39,2.38-1.39,0-2.5-.79-2.5-2.46,0-2.56,2.81-3.26,6.02-3.25v2.32Z"/>
               <polygon className="walmart__logo-1" points="50.36 35.71 55.39 35.71 55.39 30.45 55.39 10.94 50.36 10.94 50.36 35.71"/>
               <path className="walmart__logo-1" d="M130.53,21.54v-4.74h-4.38v-5.86h-4.96V29.22c0,2.52,.47,4.28,1.49,5.36,.88,.94,2.34,1.55,4.09,1.55,1.49,0,2.95-.28,3.64-.54l-.07-3.93c-.51,.13-1.1,.23-1.91,.23-1.71,0-2.29-1.1-2.29-3.36v-6.99h4.38Z"/>
               <path className="walmart__logo-1" d="M112.9,20.61h-.14v-3.51h-4.55v18.61h5.19v-9.53c0-.51,.03-.96,.11-1.37,.39-2,1.91-3.27,4.1-3.27,.6,0,1.03,.06,1.49,.13v-4.87c-.39-.08-.65-.11-1.14-.11-1.94,0-4.14,1.25-5.06,3.93Z"/>
               <path className="walmart__logo-1" d="M80.43,16.69c-1.47,0-2.63,.37-3.67,1.01-.88,.54-1.67,1.31-2.36,2.32h-.07c-.8-2.01-2.68-3.33-5.13-3.33-3.15,0-4.56,1.59-5.42,2.95h-.08v-2.53h-4.8v18.61h5.06v-10.91c0-.51,.06-1.05,.24-1.52,.42-1.1,1.44-2.39,3.07-2.39,2.04,0,2.99,1.72,2.99,4.22v10.6h5.05v-11.04c0-.49,.07-1.08,.21-1.51,.41-1.25,1.52-2.27,3.03-2.27,2.07,0,3.06,1.7,3.06,4.62v10.19h5.05v-10.96c0-5.78-2.94-8.07-6.25-8.07Z"/>
             </g>
             <path className="walmart__logo-2" d="M157.71,0c-1.62,0-2.91,.91-2.91,2.01l.99,11.17c.11,.67,.93,1.19,1.93,1.19,1,0,1.82-.52,1.93-1.19l.99-11.17c0-1.1-1.29-2.01-2.92-2.01Z"/>
             <polygon className="walmart__logo-2" points="157.72 14.37 157.72 14.37 157.71 14.37 157.72 14.37"/>
             <path className="walmart__logo-2" d="M142.43,9.15c-.95-.55-2.38,.11-3.2,1.52-.81,1.41-.67,2.98,.28,3.53l10.16,4.73c.64,.24,1.5-.22,2-1.08h0c.5-.86,.46-1.84-.07-2.27l-9.18-6.44Z"/>
             <path className="walmart__logo-2" d="M163.75,17.85c.5,.86,1.36,1.32,2,1.08l10.16-4.73c.96-.55,1.09-2.12,.28-3.53-.81-1.41-2.24-2.07-3.2-1.52l-9.18,6.44c-.53,.43-.56,1.41-.07,2.27h0Z"/>
             <polygon className="walmart__logo-2" points="157.71 28.31 157.72 28.31 157.72 28.31 157.71 28.31"/>
             <path className="walmart__logo-2" d="M157.72,28.31c-1,0-1.82,.52-1.93,1.19l-.99,11.17c0,1.1,1.29,2.01,2.91,2.01s2.92-.91,2.92-2.01l-.99-11.17c-.11-.67-.93-1.19-1.93-1.19Z"/>
             <polygon className="walmart__logo-2" points="163.75 24.83 163.75 24.83 163.75 24.83 163.75 24.83"/>
             <path className="walmart__logo-2" d="M175.92,28.48l-10.16-4.73c-.64-.24-1.49,.21-2,1.08-.49,.86-.46,1.84,.07,2.27l9.18,6.44c.96,.55,2.39-.12,3.2-1.52,.81-1.41,.68-2.98-.28-3.53Z"/>
             <path className="walmart__logo-2" d="M151.68,24.83c-.5-.87-1.36-1.32-2-1.08l-10.16,4.73c-.95,.55-1.09,2.13-.28,3.54,.81,1.4,2.25,2.07,3.2,1.52l9.18-6.43c.53-.43,.57-1.41,.07-2.27h0Z"/>
           </g>
         </g>
      </svg>
    )
  }
}

export default LogoWalmart;
