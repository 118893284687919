import React, { Component } from 'react'

class LogoItWorks extends Component {
  render() {
    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 24">
        <path className="cls-1" d="M99.81,6a1.5,1.5,0,0,0-.53-.53,1.39,1.39,0,0,0-.72-.19,1.44,1.44,0,0,0-.72.19,1.5,1.5,0,0,0-.53.53,1.55,1.55,0,0,0-.19.72,1.43,1.43,0,0,0,.72,1.25,1.44,1.44,0,0,0,.72.19,1.39,1.39,0,0,0,.72-.19,1.5,1.5,0,0,0,.53-.53,1.39,1.39,0,0,0,.19-.72A1.41,1.41,0,0,0,99.81,6Zm-.18,1.34a1.11,1.11,0,0,1-.45.46,1.19,1.19,0,0,1-.61.17,1.27,1.27,0,0,1-.63-.17,1.17,1.17,0,0,1-.45-.46,1.2,1.2,0,0,1-.17-.62,1.23,1.23,0,0,1,.17-.62,1.17,1.17,0,0,1,.45-.46,1.27,1.27,0,0,1,.63-.17,1.19,1.19,0,0,1,.61.17,1.11,1.11,0,0,1,.45.46,1.22,1.22,0,0,1,0,1.24Z"/>
        <path className="cls-1" d="M99.23,7.23V7a.49.49,0,0,0,0-.25L99,6.66l.17-.15,0-.22A.43.43,0,0,0,99.17,6L99,5.88a1,1,0,0,0-.35,0H98V7.49h.26V6.8h.42a.56.56,0,0,1,.21,0l.08.06L99,7l0,.2a1.45,1.45,0,0,0,0,.25h.29l0,0Zm-.35-.71a.63.63,0,0,1-.25,0h-.41V6.07h.43a.39.39,0,0,1,.26.06l.07.18A.22.22,0,0,1,98.88,6.52Z"/>
        <path className="cls-1" d="M95.08,16.94a1.1,1.1,0,0,0-.79.32,1.14,1.14,0,0,0-.33.8,1.11,1.11,0,0,0,.33.79,1.11,1.11,0,0,0,1.58,0,1.1,1.1,0,0,0,.32-.79,1.13,1.13,0,0,0-.32-.8A1.1,1.1,0,0,0,95.08,16.94Z"/>
        <rect className="cls-2" x="94.41" y="5.27" width="1.32" height="10.33"/>
        <path className="cls-1" d="M64.18,8.82a4.87,4.87,0,0,0-3.78,1.66,5.28,5.28,0,0,0,.08,7.16,5.21,5.21,0,0,0,7.4,0A5.29,5.29,0,0,0,68,10.47,4.88,4.88,0,0,0,64.18,8.82Zm3.34,7.23a3.7,3.7,0,0,1-1.4,1.43,3.92,3.92,0,0,1-1.94.5,3.86,3.86,0,0,1-1.93-.5,3.73,3.73,0,0,1-1.41-1.43,4.07,4.07,0,0,1-.51-2,3.93,3.93,0,0,1,1.13-2.83,3.75,3.75,0,0,1,5.44,0A3.94,3.94,0,0,1,68,14.06,4.08,4.08,0,0,1,67.52,16.05Z"/>
        <polygon className="cls-1" points="58.16 5.61 55.4 15.21 51.62 5.61 51.35 5.61 47.53 15.21 44.8 5.61 43.44 5.61 47.21 18.93 47.46 18.93 51.49 8.85 55.45 18.93 55.69 18.93 59.52 5.61 58.16 5.61"/>
        <path className="cls-1" d="M73.7,9.24a4.66,4.66,0,0,0-1.22,1.27V9.08H71.19v9.85h1.29V15.6a16.13,16.13,0,0,1,.23-3.41,3.31,3.31,0,0,1,.91-1.62,1.84,1.84,0,0,1,1.22-.52,1.72,1.72,0,0,1,.67.18l.66-1.07a2.28,2.28,0,0,0-1.12-.34A2.44,2.44,0,0,0,73.7,9.24Z"/>
        <path className="cls-1" d="M89.1,13.32a5,5,0,0,1-1.53-1,1.4,1.4,0,0,1-.36-.91,1.24,1.24,0,0,1,.44-.95A1.5,1.5,0,0,1,88.71,10a3,3,0,0,1,2,1l.81-.85a4,4,0,0,0-2.74-1.33,2.7,2.7,0,0,0-1.94.74,2.5,2.5,0,0,0-.76,1.87A2.58,2.58,0,0,0,86.55,13a5.15,5.15,0,0,0,1.81,1.36,5.5,5.5,0,0,1,1.6,1,1.45,1.45,0,0,1,.35,1,1.5,1.5,0,0,1-.53,1.15,1.85,1.85,0,0,1-1.3.49,2.78,2.78,0,0,1-2.06-1.1l-.8.91a3.61,3.61,0,0,0,1.29,1,3.66,3.66,0,0,0,1.62.37,2.94,2.94,0,0,0,2.14-.84,2.77,2.77,0,0,0,.85-2.05,2.56,2.56,0,0,0-.5-1.55A5.64,5.64,0,0,0,89.1,13.32Z"/>
        <polygon className="cls-1" points="84.94 9.08 83.07 9.08 78.5 13.05 78.5 5.27 77.24 5.27 77.24 18.93 78.5 18.93 78.5 14.49 83.47 18.93 85.25 18.93 79.52 13.78 84.94 9.08"/>
        <rect className="cls-2" x="28.38" y="5.61" width="1.33" height="13.32"/>
        <polygon className="cls-1" points="34.93 5.42 33.66 5.42 33.66 9.08 31.94 9.08 31.94 10.17 33.66 10.17 33.66 18.93 34.93 18.93 34.93 10.17 36.94 10.17 36.94 9.08 34.93 9.08 34.93 5.42"/>
        <path className="cls-1" d="M22.85,7.8l-5.22,3.85,4.74,1.58s-4.11-.48-6.21.76a12.51,12.51,0,0,0-3,3.24s-1.58-3.16,1.69-7.49c2.05-2.71,5.46-3.1,7.54-3.06A11.82,11.82,0,0,0,3.17,3.94l6,2.5L8.08,1.57S9.79,5.34,11.94,6.5a12.24,12.24,0,0,0,4.33.92s-1.89,3-7.29,2.41c-3.44-.37-5.55-3.18-6.56-5A11.82,11.82,0,0,0,9.58,23.6L8.9,16.39,5.11,19.64s2.52-3.28,2.54-5.72A12.28,12.28,0,0,0,6.43,9.66S10,9.93,12,15c1.46,3.63-.64,7.23-1.73,8.74A11.81,11.81,0,0,0,23.22,9,10.3,10.3,0,0,0,22.85,7.8Z"/>
      </svg>
    )
  }
}

export default LogoItWorks;
