import React, { Component } from 'react'
import axios from 'axios'
import { Form, Text } from 'react-form'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import FormSuccess from '../Success'

class ContactForm extends Component {
  constructor() {
    super();

    this.state = {
      formComplete: false,
      isSubmitted: false
    }

    this.renderForm = this.renderForm.bind(this);
  }

  onFormSubmit = (values) => {
    let vm = this;

    const { name, email } = values;

    axios.post('https://itsmedrew.com/mailer/mailer.php', {
          name,
          email
      })
      .then((result) => {
          vm.setState(prevState => ({
            isSubmitted: true
          }));
      });
  }

  renderFormHeader = () => {
    if (!this.state.isSubmitted) {
      return (
        <div className="contact-form__header">
          <h4 className="contact-form__heading">
            secret code request
          </h4>
        </div>
      )
    }
  }

  renderForm = () => {
    return (
      <div className="contact-form__form">
        <Form
          onSubmit={ this.onFormSubmit }
          validate={ values => {
            const { name, email } = values
            return {
              name: !name ? 'Your name is required' : undefined,
              email: !email ? 'An email address is required' : undefined,
            }
          }}
        >
          {({values, submitForm}) => {
            const isComplete = !!values.name && values.comment && values.phone;

            return (

              <form onSubmit={ submitForm }>
                <Text placeholder="your name" field="name" />
                <Text placeholder="your email" field="email" />
                <button className={classnames('contact-form__button', {isDisabled: !isComplete})} type="submit">send</button>
              </form>
            )
          }}
        </Form>
      </div>
    )
  }

  render() {
    return (
      <div className="contact-form">
        { this.renderFormHeader() }
        { this.state.isSubmitted ? <FormSuccess /> : this.renderForm() }
      </div>
    )
  }
}

ContactForm.propTypes = {
  title: PropTypes.string
}

export default ContactForm;
