import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function PasswordGate({ children }) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectURL = queryParams.get('redirect');

  const PASSWORD = 'coffee';

  const handleSubmit = (e) => {
    e.preventDefault();

   if (password === PASSWORD) {
     window.location.href = redirectURL;
     setPasswordError(false);
   } else {
     setPasswordError(true);
     setPassword('');
   }
  };

  return (
    <div className="password">
      <h2>What's the magic word?</h2>
      <p>I'd <span role="img" aria-label="heart">❤️</span> to show you what I've worked on, reach out to me on <a href="https://www.linkedin.com/in/itsmedrew/" target="_blank" rel="noopener noreferrer">LinkedIn</a> or <a href="mailto:drew@itsmedrew.com?subject = Magic Word&body = I'd like to read your full case study.">e-mail</a>, I'm the world's fastest responder.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          placeholder="Magic word"
          onChange={e => setPassword(e.target.value)}
          style={{ borderColor: passwordError ? 'red' : undefined }}
        />
        {passwordError && <div style={{ color: 'red' }}>Please try again or reach out to me for the password.</div>}
        <button className="form__button" type="submit">Submit</button>
      </form>
    </div>
  );
}
