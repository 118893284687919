import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

class ButtonSecondary extends Component {
  renderClick(e) {
    e.preventDefault();

    this.props.onClick();
  }

  render() {
    const {to, children, className, internal, external} = this.props

    if (internal) {
      return (
        <a className={classnames('btn btn-secondary btn--outlined', className)} href={ to }><span>{children}</span></a>
      )
    }

    if (external) {
      return (
        <a className={classnames('btn btn-secondary btn--outlined', className)} href={ to } target="_blank" rel="noopener noreferrer"><span>{children}</span></a>
      )
    }

    return (
      <NavLink className={classnames('btn btn--outlined btn-secondary', className)} to={ to }>{ children }</NavLink>
    )
  }
}

ButtonSecondary.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.string,
  internal: PropTypes.string
}

export default ButtonSecondary;
