import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import reducers from './reducers'

const logger = createLogger();
const store = applyMiddleware(thunkMiddleware, logger)(createStore);

export default store(reducers);
