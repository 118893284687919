import React, { Component } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../../store/actions'
import Rating from '../../../../Components/Rating'
import WorkLayout from '../../../../layouts/work'
import PageHeader from '../../../../Components/PageHeaders/Secondary'
import FeatureHighlight from '../../../../Components/ContentBlock/FeatureHighlight/'
import PublixMVPLowFi from '../../../../images/publix-mvp-lowfi.jpg'
import PublixMVPImage from '../../../../images/publix-mvp-infographic.png'
import PublixTaxGroupIllustrationImage from '../../../../images/publix-tax-group-illustration.png'
import PublixTaxRateIllustrationImage from '../../../../images/publix-tax-rate-illustration.png'
import PublixTaxApprovalIllustrationImage from '../../../../images/publix-tax-approval-illustration.png'
import PublixTaxWorkshopImage from '../../../../images/publix-workshop-1.jpg'
import PublixTaxGroupHighfiImage from '../../../../images/publix-tax-group-highfi.png'
import PublixTaxCurrentImage from '../../../../images/publix-tax-current.png'
import PublixTaxInterviewMVPImage from '../../../../images/publix-tax-interview-mvp.png'
import PublixTaxGroupInterviewImage from '../../../../images/publix-tax-group-interview.png'
import PublixTFEUploadImage from '../../../../images/publix-tfe-upload.png'
import PublixTFEDeltasImage from '../../../../images/publix-tfe-deltas.png'
import PublixTFEFinalImage from '../../../../images/publix-tfe-final-decor.png'
import LinkWithIcon from '../../../../Components/Links/IconLabel'

function mapStateToProps(state) {
  return { page: state.pageList.productPage };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class PublixCS extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    let prototypeLink = "https://www.figma.com/proto/pYHFKvFlwhZBchjTlSqgDI/(Archive)-Publix-TFE?page-id=362%3A3230&type=design&node-id=362-3294&viewport=4788%2C464%2C0.7&t=IEG23xdVpj0CPBFl-1&scaling=min-zoom&starting-point-node-id=362%3A3294&mode=design"

    return (
      <WorkLayout header="secondary" className="publix">
        <PageHeader
            title="Discovery - Modernizing Publix's mainframe systems into a new experience"
            time="2022 - 2023 • Product Strategy | Facilitator | Acting BSA | Lead Designer"
            featuredImage={ PublixTFEFinalImage }
            figmaLink={ prototypeLink }
            >
        </PageHeader>
        <Container>
          <Row className="work__block">
            <Col className="work__column" md={8}>
              <header className="work-header work__callout">
                <h2>01. Discovering our MVP</h2>
                <h3>The new Publix Item System is your daily companion tax application accessible from your desktop. Choose an item in the queue, determine tax plan, and you're ready to apply the tax how you normally do today.</h3>
              </header>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6}>
              <img className="work__image" src={ PublixTaxInterviewMVPImage } alt="UX MVP Discovery Screenshot"/>
            </Col>
            <Col className="work__column" md={6}>
              <header className="work-header">
                <h5>1/2 Discovery- Finding Value</h5>
                <h3>I started by finding the most valuable & feasibile problem to solve</h3>
              </header>
              <p>We kicked off with a discovery phase, using abstract designs to facilitate conversations and get insights from users. Instead of just building what they asked for, we talked to many users to understand the real reasons behind their needs. This helped us figure out the core features for our MVP.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={6}>
              <img className="work__image" src={ PublixMVPLowFi } alt="UX Discovery MVP Design Progression"/>
            </Col>
            <Col className="work__column" md={6}>
              <header className="work-header">
                <h5>2/2 Discovery - Rapid Solutioning</h5>
                <h3>Introducing a new item queue</h3>
              </header>
              <p>Our solution to deliver rapid value and allow developement to get started without being bogged down by design intricacies was to introduce a streamlined new item queue. Sounds simple, however this queue would prove valuable for our users who currently work through flagged emails, removing the need to research the item in other systems.</p>
            </Col>
          </Row>
          <Row className="work__divider">
            <Col className="work__column">
              <img className="work__image" src={ PublixMVPImage } alt="Publix Tax System MVP High-fi Design"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8}>
              <header className="work-header work__callout">
                <h2>02. Eliminate current system</h2>
                <h3>Phase 2 addressed the limitations of the existing item grouping system, which, while efficient for Buyers, hindered accurate taxation. The solution involved a separate, tax-oriented grouping system.</h3>
              </header>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={5}>
              <img className="work__image" src={ PublixTaxCurrentImage } alt="Current Tax Process Illustration"/>
            </Col>
            <Col className="work__column" md={6} push={{ md: 1 }}>
              <header className="work-header">
                <h5>Discovery 1/3 - Problem Prioritization</h5>
                <h3>Finding the right problems to solve</h3>
              </header>
              <p>I began conducting another round of discovery with a goal to indentify a better way to apply tax. Through 2x2 plotting, we chose to address the following key issues:</p>
              <ol>
                <li>There are currently three sources of truth which complicates the auditing process.</li>
                <li>How items are currently groups complicates the taxing process.</li>
                <li>Errors in tax costs the company a significant amount of money per year.</li>
                <li>Approvals are handled by paper causing a physical storage mess.</li>
              </ol>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={10}>
              <header className="work-header work__callout">
                <h2>Problem Statement:</h2>
                <h3>HMW simplify the taxation process to avoid audit complexities and tax inaccuracies caused by the current item grouping system?</h3>
              </header>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={5}>
              <img className="work__image" src={ PublixTaxGroupInterviewImage } alt="UX Discovery User Interview"/>
            </Col>
            <Col className="work__column"  md={6} push={{ md: 1 }}>
              <header className="work-header">
                <h5>Discovery 2/3 - Target</h5>
                <h3>We found our Ahh-Hah moment <span role="img" aria-label="mind-blown">🤯</span></h3>
              </header>
              <p>Early on in discovery we found that the way our Buyers grouped the items favored their workflows, but did not allow for accurate taxing. This lead to the hypothetical question of:</p>
              <p className="work__quote">“What if you had your own grouping system, how would that change things?” - <b>Curious me</b></p>
              <p className="work__quote">"Well.. if that group somehow had tax settings on it, then I guess we'd just be done." - <b>User</b></p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={5}>
              <img className="work__image" src={ PublixTaxWorkshopImage } alt="Working as a team to find a solution"/>
            </Col>
            <Col className="work__column"  md={6} push={{ md: 1 }}>
              <header className="work-header">
                <h5>Discovery 3/3 - Validating Hypothesis</h5>
                <h3>Our solution was almost ruined <span role="img" aria-label="scared">🫣</span></h3>
              </header>
              <p>We started exploring this concept by identifying how many unique tax plans exist today (this equals how many tax groups are required). Initially, we were shocked to learn that the number was around 500, which would make our solution too complicated.</p>
              <p>Working alongside our users, we found that many were either duplicates, errors, or a single item which brought our number to a much more reasonable ~30.</p>
            </Col>
          </Row>
        </Container>
        <Row className="feature-block">
          <Col className="feature-block__feature" xs={8} md={4} push={{ xs:2, md:0 }}>
            <FeatureHighlight image={PublixTaxGroupIllustrationImage} title="Empower Tax Groups" text="Drop the item; let the group handle the details!"/>
          </Col>
          <Col className="feature-block__feature" xs={8} md={4} push={{ xs:2, md:0 }}>
            <FeatureHighlight image={PublixTaxRateIllustrationImage} title="Tax Rate Mastery" text="One source, streamlined for every tax group."/>
          </Col>
          <Col className="feature-block__feature" xs={8} md={4} push={{ xs:2, md:0 }}>
            <FeatureHighlight image={PublixTaxApprovalIllustrationImage} title="Approvals Made Easy" text="From paper stacks to sleek tracks. No more printing."/>
          </Col>
        </Row>
        <Container>
          <Row className="work__divider">
            <Col className="work__column">
              <img className="work__image" src={ PublixTaxGroupHighfiImage } alt="Tax Group High-Fi Design Solution"/>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8}>
              <header className="work-header work__callout">
                <h2>03. Create Tax Events</h2>
                <h3>Phase 3 tackled the financial costs arising from inaccurate tax collection during tax-free events by empowering users with the ability to create highly tailored tax-free scenarios.</h3>
              </header>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={5}>
              <img className="work__image" src={ PublixTFEUploadImage } alt="Creating a new event modal"/>
            </Col>
            <Col className="work__column"  md={6} push={{ md: 1 }}>
              <header className="work-header">
                <h5>1/2 - Validating Hypothesis</h5>
                <h3>Upload Documents -> We do the rest</h3>
              </header>
              <p>If we leverage the Excel files that are sent, enabling users to upload these files along with supporting PDF documents, and automate item mapping via item code, coupled with a simple email-based approval process, we predict it will facilitate the creation of all tax-free events.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={5}>
              <img className="work__image" src={ PublixTFEDeltasImage } alt="Highlighting the dfeltas for current year"/>
            </Col>
            <Col className="work__column"  md={6} push={{ md: 1 }}>
              <header className="work-header">
                <h5>2/2 - Discovering Value</h5>
                <h3>Highlight the Deltas</h3>
              </header>
              <p>While observing users with our prototype, we noted their frequent reference to Excel docs to spot differences from the previous year. Instead of just highlighting these changes on the UI, we decided it'd be more efficient to ask users to upload only new items for the year. By automatically pulling items from the prior year in the background, we sidestep potential edge cases and enhance user value.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8}>
              <header className="work-header work__callout">
                <h2>Final Designs</h2>
                <h3>I'm proud to say we drastically improved our users daily work by significantly streamlining the process, eliminating errors, and most importantly showcased the value discovery research brings to the business.</h3>
                <LinkWithIcon className="work__link" to={ prototypeLink } icon="slideshow">View Figma Prototype</LinkWithIcon>
              </header>
            </Col>
          </Row>
          <Row className="work__divider">
            <Col className="work__column">
              <img className="work__image" src={ PublixTFEFinalImage } alt="Final Design images"/>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(PublixCS)
);
