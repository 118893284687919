import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class FrameBlock extends Component {
  render() {
    const {children, className} = this.props

    return (
      <div className={classnames('frame-block', className)}>
        <div className="frame-block__container">
          {children}
        </div>
      </div>
    )
  }
}

FrameBlock.propTypes = {
  className: PropTypes.string
}

FrameBlock.defaultProps = {

}

export default FrameBlock;
