export const FETCH_FEEDBACK_REQUEST  = 'FETCH_FEEDBACK_REQUEST';
export const FETCH_FEEDBACK_SUCCESS  = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_ERROR    = 'FETCH_FEEDBACK_ERROR';

export const ADD_FEEDBACK_REQUEST  = 'ADD_FEEDBACK_REQUEST';
export const ADD_FEEDBACK_SUCCESS  = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_ERROR    = 'ADD_FEEDBACK_ERROR';

export const DELETE_FEEDBACK_REQUEST  = 'DELETE_FEEDBACK_REQUEST';
export const DELETE_FEEDBACK_SUCCESS  = 'DELETE_FEEDBACK_SUCCESS';
export const DELETE_FEEDBACK_ERROR    = 'DELETE_FEEDBACK_ERROR';
