import React, { Component } from 'react'

class Dislike extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="arrow-down"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>dislike</title>
        <path d="M21,11.06a1.85,1.85,0,0,0-.68-2.53,1.82,1.82,0,0,0,.38-1.12A1.84,1.84,0,0,0,19.2,5.6a1.83,1.83,0,0,0,.24-.9,1.85,1.85,0,0,0-1.86-1.85H12.39a11.6,11.6,0,0,0-4.86,1,1.34,1.34,0,0,0-1-.43H2.86A1.36,1.36,0,0,0,1.5,4.81v9a1.36,1.36,0,0,0,1.36,1.36H6.54a1.35,1.35,0,0,0,1.35-1.36v0c2.54.18,5.88,1.64,5.88,3.62v1.88a1.86,1.86,0,1,0,3.72,0A18.52,18.52,0,0,0,17,14.74h3.61A1.86,1.86,0,0,0,21,11.06ZM6.89,13.81a.36.36,0,0,1-.35.36H2.86a.36.36,0,0,1-.36-.36v-9a.36.36,0,0,1,.36-.35H6.54a.35.35,0,0,1,.35.35v9Zm9.6,5.48a.86.86,0,1,1-1.72,0V17.41c0-2.86-4.23-4.44-6.88-4.62v-8a10.83,10.83,0,0,1,4.5-1h5.19a.86.86,0,0,1,0,1.71h-2a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h3.23a.86.86,0,1,1,0,1.71H16.77a.5.5,0,0,0,0,1h2.64a.88.88,0,0,1,.86.88.87.87,0,0,1-.86.86H17.88a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h2.76a.86.86,0,0,1,0,1.72H14.39a.5.5,0,0,0-.5.5.51.51,0,0,0,.5.5H16A16.64,16.64,0,0,1,16.49,19.29Z"/>
        <path d="M5.54,6.94a.42.42,0,0,0-.42.42v5.23a.42.42,0,1,0,.84,0V7.36A.42.42,0,0,0,5.54,6.94Z"/>
        <path d="M5.54,5.62a.48.48,0,0,0,0,1,.48.48,0,1,0,0-1Z"/>
      </svg>
    )
  }
}

export default Dislike;
