import React from 'react';
import Drew from './drew';
import ItWorks from './itworks';
import DoorDash from './doordash';
import Nextech from './nextech';
import Walmart from './walmart';
import Publix from './publix';
import Tropicana from './tropicana';

const Logo = props => {
  switch(props.name) {
    case "drew":
      return <Drew {...props} />;
    case "itworks":
      return <ItWorks {...props} />;
    case "doordash":
      return <DoorDash {...props} />;
    case "nextech":
      return <Nextech {...props} />;
    case "walmart":
      return <Walmart {...props} />;
    case "publix":
      return <Publix {...props} />;
    case "tropicana":
      return <Tropicana {...props} />;
    default:
      return <div />;
  }
}
export default Logo;
