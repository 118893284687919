import React, { Component } from 'react'

class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <div className="loading__graphic">
          <div className="cup">
            <div className="bubble bubble-1"></div>
            <div className="bubble bubble-2"></div>
            <div className="bubble bubble-3"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Loading;
