const Auth = {
  isAuthenticated: false,
  authenticate(text, cb) {
    const password = text.toLowerCase()

    if (password === 'drew') {
      this.isAuthenticated = true
    }

    setTimeout(cb, 100)
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}

export default Auth;
