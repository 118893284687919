import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyBaH0aViZgVQBu2kbIQ-vWNT94GWes17nU",
  authDomain: "itsmedrew-a9c95.firebaseapp.com",
  databaseURL: "https://itsmedrew-a9c95.firebaseio.com",
  projectId: "itsmedrew-a9c95",
  storageBucket: "itsmedrew-a9c95.appspot.com",
  messagingSenderId: "501230550087",
  appId: "1:501230550087:web:f2c220a1fa160734090562",
  measurementId: "G-CT9S9ZLD6T"
}

firebase.initializeApp(config);

export const databaseRef = firebase.database().ref();
