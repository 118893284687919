import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

class FAQItem extends Component {
  render() {
    const { question, answer } = this.props;

    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            {question}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>{answer}</p>
        </AccordionItemPanel>
      </AccordionItem>
    )
  }
}

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default FAQItem;
