import React, { Component } from 'react'

class ChevronRight extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="chevron-right"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.71 18.52"
      >
        <title>chevron-right</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon points="1.41 18.52 0 17.1 7.88 9.26 0 1.42 1.41 0 10.71 9.26 1.41 18.52"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default ChevronRight;
