import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class InfoHighlight extends Component {
  render () {
    const {children, className} = this.props

    return (
      <div className={classnames('info-highlight', className)}>
        {children}
      </div>
    )
  }
}

InfoHighlight.defaultProps = {
  className: ''
}

InfoHighlight.propTypes = {
  className: PropTypes.string
}

export default InfoHighlight;
