import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import { Container, Row, Col } from 'react-grid-system'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import SecondaryPageHeader from '../../../Components/PageHeaders/Secondary'

function mapStateToProps(state) {
  return { page: state.pageList.trapink };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class Trapink extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <SecondaryPageHeader
            title="Trapink Tattoos"
            subtitle="Design and Developed with React & Wordpress API"
            />
        <Row className="work__block work__conclusion">
          <Container>
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Container>
        </Row>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(Trapink)
);
