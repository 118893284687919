import React, { Component } from 'react'

class User extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="user"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>user</title>
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="m10,20c-1.37,0-2.66-.26-3.88-.79-1.22-.53-2.28-1.24-3.19-2.15-.91-.91-1.62-1.97-2.15-3.19-.53-1.22-.79-2.51-.79-3.88s.26-2.68.79-3.9c.52-1.22,1.24-2.27,2.15-3.17s1.97-1.61,3.19-2.14c1.22-.52,2.51-.79,3.88-.79s2.68.26,3.9.79c1.22.52,2.27,1.24,3.18,2.14.9.9,1.61,1.96,2.14,3.17.52,1.22.79,2.52.79,3.9s-.26,2.66-.79,3.88c-.53,1.22-1.24,2.28-2.14,3.19-.9.91-1.96,1.62-3.18,2.15-1.22.52-2.52.79-3.9.79Zm0-18c-1.85,0-3.48.55-4.9,1.66-1.42,1.11-2.36,2.52-2.82,4.24.33-.08.68-.25,1.04-.49.36-.24.77-.83,1.24-1.76.25-.52.62-.92,1.1-1.21.48-.29,1.02-.44,1.6-.44h5.5c.58,0,1.12.15,1.6.44.48.29.85.7,1.1,1.21.47.95.89,1.54,1.26,1.77.38.23.71.39,1.01.48-.47-1.72-1.4-3.13-2.81-4.24-1.41-1.11-3.05-1.66-4.91-1.66Zm0,16c2.23,0,4.13-.78,5.69-2.35,1.56-1.57,2.33-3.47,2.31-5.7-1.18-.12-2.09-.49-2.73-1.11-.63-.62-1.17-1.39-1.62-2.29-.08-.18-.2-.32-.36-.41-.16-.09-.33-.14-.51-.14h-5.53c-.2,0-.38.05-.54.14-.16.09-.28.23-.36.41-.45.92-1,1.69-1.65,2.31-.65.62-1.55,1-2.7,1.11,0,2.23.78,4.13,2.34,5.69,1.56,1.56,3.45,2.34,5.66,2.34Zm-3-5.75c-.35,0-.65-.12-.89-.36-.24-.24-.36-.54-.36-.89s.12-.65.36-.89c.24-.24.54-.36.89-.36s.65.12.89.36c.24.24.36.54.36.89s-.12.65-.36.89c-.24.24-.54.36-.89.36Zm6,0c-.35,0-.65-.12-.89-.36-.24-.24-.36-.54-.36-.89s.12-.65.36-.89c.24-.24.54-.36.89-.36s.65.12.89.36c.24.24.36.54.36.89s-.12.65-.36.89-.54.36-.89.36Z"/>
        </g>
      </svg>
    )
  }
}

export default User;
