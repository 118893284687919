import React, { Component } from 'react'

class LogoDoorDash extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      	 viewBox="0 0 154 18">
      <path d="M44.7,5.1v7.9h2c1,0,2-0.4,2.7-1.2C50,11,50.4,10,50.4,9c0-1-0.4-2-1.1-2.8c-0.7-0.7-1.7-1.2-2.7-1.2L44.7,5.1
      	L44.7,5.1z M46.6,2.6c3.7,0,6.4,2.8,6.4,6.4s-2.8,6.4-6.4,6.4h-4.3c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0V2.9c0-0.2,0.1-0.3,0.3-0.3
      	C42.3,2.6,46.6,2.6,46.6,2.6z M62.5,13.2c1.7,0,3.2-1,3.8-2.6c0.6-1.6,0.3-3.4-0.9-4.6c-1.2-1.2-2.9-1.6-4.5-0.9
      	c-1.5,0.6-2.5,2.2-2.5,3.9C58.4,11.3,60.2,13.2,62.5,13.2 M62.5,2.3c3.8,0,6.8,3,6.8,6.7s-2.9,6.7-6.8,6.7c-3.8,0-6.8-3-6.8-6.7
      	S58.7,2.3,62.5,2.3 M78.6,13.2c2.3,0,4.1-1.9,4.1-4.2c0-2.3-1.8-4.2-4.1-4.2c-2.3,0-4.1,1.9-4.1,4.2c0,1.1,0.4,2.2,1.2,3
      	C76.5,12.8,77.5,13.2,78.6,13.2 M78.6,2.3c3.8,0,6.8,3,6.8,6.7s-3,6.7-6.8,6.7c-3.8,0-6.8-3-6.8-6.7S74.8,2.3,78.6,2.3 M94.4,5.1
      	h-2.8v3.4h2.8c0.4,0,0.9-0.1,1.2-0.5C95.9,7.7,96,7.3,96,6.8c0-0.5-0.2-0.9-0.5-1.2C95.3,5.2,94.8,5.1,94.4,5.1L94.4,5.1L94.4,5.1z
      	 M88.9,2.9c0-0.2,0.1-0.3,0.3-0.3h5.2c2.5,0,4.3,1.9,4.3,4.2c0,1.6-0.8,3-2.2,3.7l2.4,4.4c0.1,0.1,0.1,0.2,0,0.3
      	c-0.1,0.1-0.2,0.2-0.3,0.2h-2.1c-0.1,0-0.2-0.1-0.3-0.2l-2.3-4.3h-2.3v4.1c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0h-2
      	c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0L88.9,2.9L88.9,2.9z M105.7,5.1V13h2c1,0,2-0.4,2.7-1.2c0.7-0.7,1.1-1.7,1.1-2.8
      	c0-1-0.3-2-1.1-2.8c-0.7-0.7-1.7-1.2-2.7-1.2C107.6,5.1,105.7,5.1,105.7,5.1z M107.6,2.7c3.7,0,6.4,2.8,6.4,6.4s-2.8,6.4-6.4,6.4
      	h-4.3c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0V3c0-0.2,0.1-0.3,0.3-0.3L107.6,2.7L107.6,2.7z M122.2,5.8l-1.5,4.2h3L122.2,5.8z
      	 M119.8,12.4l-1,2.8c0,0.1-0.2,0.2-0.3,0.2h-2.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2,0-0.3l4.7-12.1c0-0.1,0.2-0.2,0.3-0.2h2.4
      	c0.1,0,0.3,0.1,0.3,0.2l4.7,12.1c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-2.2c-0.1,0-0.3-0.1-0.3-0.2l-1-2.8L119.8,12.4
      	L119.8,12.4z M130.9,6.1c0-2,1.7-3.7,4.4-3.7c1.4,0,2.8,0.5,3.9,1.5c0.1,0.1,0.1,0.3,0,0.5c0,0,0,0,0,0l-1.2,1.2
      	c-0.1,0.1-0.3,0.1-0.4,0c0,0,0,0,0,0c-0.6-0.5-1.3-0.8-2.1-0.8c-1.1,0-1.9,0.6-1.9,1.4c0,2.4,6.3,1,6.3,5.6c0,2.3-1.7,4.1-4.7,4.1
      	c-1.7,0-3.3-0.7-4.5-1.9c-0.1-0.1-0.1-0.3,0-0.5c0,0,0,0,0,0l1.2-1.2c0.1-0.1,0.3-0.1,0.4,0c0,0,0,0,0,0c0.7,0.7,1.7,1.1,2.7,1.1
      	c1.3,0,2.2-0.7,2.2-1.6C137.2,9.3,130.9,10.7,130.9,6.1 M151.4,2.9v4.8H146V2.9c0-0.2-0.1-0.3-0.3-0.3c0,0,0,0,0,0h-2
      	c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0,0,0v12.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3v-4.9h5.3v4.9c0,0.2,0.1,0.3,0.3,0.3h2
      	c0.2,0,0.3-0.1,0.3-0.3V2.9c0-0.2-0.1-0.3-0.3-0.3c0,0,0,0,0,0h-2C151.5,2.6,151.4,2.8,151.4,2.9z M30.6,4.2C29.2,1.6,26.4,0,23.4,0
      	H0.8C0.5,0,0.2,0.2,0.1,0.5C-0.1,0.8,0,1.1,0.2,1.3l4.9,4.9c0.4,0.4,1,0.7,1.6,0.7h16c1.1,0,2.1,0.9,2.1,2c0,1.1-0.9,2-2,2.1h-11
      	c-0.3,0-0.6,0.2-0.7,0.5c-0.1,0.3-0.1,0.6,0.2,0.8l4.9,4.9c0.4,0.4,1,0.7,1.6,0.7h5C29.3,17.9,34.2,11,30.6,4.2"/>
      </svg>
    )
  }
}

export default LogoDoorDash;
