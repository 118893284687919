import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../../store/actions'
import { Container, Row, Col } from 'react-grid-system'
import Rating from '../../../../Components/Rating'
import WorkLayout from '../../../../layouts/work'
import HeaderImg from '../../../../images/workshop-iw-ds-group.jpg'
import ProposalImg1 from '../../../../images/workshop-iw-ds-proposal-3.jpg'
import HomeDepotImg from '../../../../images/workshop-iw-ds-home-depot.jpg'
import CollageImg from '../../../../images/workshop-iw-ds-collage.jpg'
import PrototypeImg from '../../../../images/workshop-iw-ds-prototype.jpg'
import PageHeader from '../../../../Components/PageHeaders/Secondary'

function mapStateToProps(state) {
  return { page: state.pageList.doordash };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class IwDesignSprint extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <PageHeader
            className="doordash__hero"
            featuredImage={HeaderImg}
            title="Our First Design Sprint"
            time="2020"
            role="Planning & Facilitating"
            >
            <p>The problem the Digital Marketing team and I constantly faced was that we would receive direction to create solutions without knowing the why, or the problem. We felt that given the opportunity to be involved in the process we could add tons of value to the products we're delivering.</p>

          </PageHeader>
        <Container>
          <Row className="work__block">
            <Col className="work__column" md={8}>
              <h2>Pitching this idea was a hard sell</h2>
              <p>At this time I had been reading into IBM's design thinking and how Home Depot was able to overcome their constraints to incorporate these workshops within their company. I got my hands on the Sprint book and in my spare time put together a pdf to pitch the idea to the VP of IT and Marketing.</p>
            </Col>
            <Col md={4}>
              <img src={HomeDepotImg} alt="Home Depot running a design sprint"/>
              <h6 className="work__caption">Home Depot during their sprint.</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={12}>
              <img src={ProposalImg1} alt="Snippet of my proposal"/>
              <h6 className="work__caption text--center">Preview of my proposal.</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>Approved for a trial run</h2>
              <p>I was super excited to get approved to run a trial sprint to prove its worth, so we needed to make sure we picked the perfect problem.</p>
              <h3>Our first problem:</h3>
              <p>How might we allow our LiveFit users to motivate and hold each other accountable so that users are more engaged and are exposed to purchasing more It Works! products.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>Planning the sprint</h2>
              <br/>
              <h3>Picking the team</h3>
              <p>One of the main benefits of a design sprint is that it brings experts together from other departments. Working with my manager we carefully chose who we thought would be a perfect fit.</p>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <img src={CollageImg} alt="Images from our design sprint"/>
              <h6 className="work__caption">Team hard at work going through guiding questions, heat map voting, and prototyping.</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>Prototyping & Testing</h2>
              <p>We selected five local Distributors to test our prototype with that represented a wide range of fitness types. The users were interviewed one at a time with one team member serving as our interviewer and the team observed on a zoom call in another room taking notes.</p>
              <img src={PrototypeImg} alt="Screenshot of the prototype"/>
              <h6 className="work__caption">Screenshot of the prototype.</h6>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>Findings</h2>
              <p>After our testing session, we gathered our findings to be presented as part of our final proposal. We had a lot of takeaways, some of the highlights were:</p>
              <ol>
                <li>Testers across all user types expected personal tracking features as the main source of personal motivation.</li>
                <li>Testers wanted filters. They expected to be able to filter by workout time, intensity, and equipment required.</li>
                <li>Testers would be more inclined to use the app in their everyday routines if it were integrated with their smartwatch. Testers did not want to add anything extra to their existing tracking apps. It would be recommended to integrate with existing Apple/Samsung health apps.</li>
                <li>The workout layout was well-received. Testers felt they could easily find workouts and the programs were easy to comprehend.</li>
                <li>Most testers that we interviewed would not work out with a friend through an app, however did like seeing who had completed the programs.</li>
              </ol>
            </Col>
          </Row>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <h2>Presenting to the business</h2>
              <p>The sprint team gathered the Stakeholders and held a recorded pitch meeting to showcase our process, explain our findings, demo the prototype and explain how implementation would work. We would go on to run 3 more successful design sprints in my time with It Works!</p>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(IwDesignSprint)
);
