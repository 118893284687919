import React, { Component } from 'react'

class Pdf extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="slideshow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 96 960 960"
      >
        <title>pdf</title>
        <path d="M353 608h37v-83h48q15.725 0 26.362-10.638Q475 503.725 475 488v-48q0-15.725-10.638-26.362Q453.725 403 438 403h-85v205Zm37-120v-48h48v48h-48Zm122 120h84q15 0 26-10.638 11-10.637 11-26.362V440q0-15.725-11-26.362Q611 403 596 403h-84v205Zm37-37V440h47v131h-47Zm129 37h37v-83h50v-37h-50v-48h50v-37h-87v205ZM286 864q-39.05 0-66.525-27.475Q192 809.05 192 770V228q0-39.463 27.475-67.231Q246.95 133 286 133h542q39.463 0 67.231 27.769Q923 188.537 923 228v542q0 39.05-27.769 66.525Q867.463 864 828 864H286Zm0-94h542V228H286v542Zm-154 249q-39.463 0-67.231-27.769Q37 963.463 37 924V288h95v636h636v95H132Zm154-791v542-542Z"/>
      </svg>
    )
  }
}

export default Pdf;
