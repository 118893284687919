import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Quote extends Component {
  render () {
    let imageEl

    const {image, children, source, alt} = this.props

    if (image) {
      imageEl = <img className="quote__image" src={image} alt={alt} />
    }

    return (
      <blockquote className="quote">
        <div className="quote__content">
          {imageEl}
          <div>
            <p className="quote__text">{children}</p>
            <span className="quote__name">{source}</span>
          </div>
        </div>
      </blockquote>
    )
  }
}

Quote.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string
}

export default Quote;
