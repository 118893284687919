import React, { Component } from 'react'

class ArrowDown extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="arrow-down"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 30"
      >
        <title>like</title>
        <path d="M21.14,9.45H17.46a1.35,1.35,0,0,0-1.21.76c-2.55-.13-6-1.61-6-3.63V4.7a1.86,1.86,0,0,0-3.72,0A18.52,18.52,0,0,0,7,9.25H3.36A1.86,1.86,0,0,0,3,12.93a1.85,1.85,0,0,0,.68,2.53A1.84,1.84,0,0,0,4.8,18.39a1.83,1.83,0,0,0-.24.9,1.86,1.86,0,0,0,1.86,1.86h5.19a11.69,11.69,0,0,0,4.57-.92,1.35,1.35,0,0,0,1.28.93h3.68A1.36,1.36,0,0,0,22.5,19.8v-9A1.36,1.36,0,0,0,21.14,9.45ZM6.42,20.15a.86.86,0,0,1,0-1.72h2a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H5.14a.86.86,0,1,1,0-1.71H7.23a.5.5,0,1,0,0-1H4.59a.88.88,0,0,1-.86-.88A.87.87,0,0,1,4.59,13H6.12a.5.5,0,0,0,0-1H3.36a.86.86,0,0,1,0-1.72H9.61a.5.5,0,0,0,.5-.5.51.51,0,0,0-.5-.5H8A16.64,16.64,0,0,1,7.51,4.7a.86.86,0,1,1,1.72,0V6.58c0,2.86,4.23,4.44,6.88,4.62v8a10.88,10.88,0,0,1-4.5,1ZM21.5,19.8a.36.36,0,0,1-.36.36H17.46a.36.36,0,0,1-.35-.36v-9a.36.36,0,0,1,.35-.36h3.68a.36.36,0,0,1,.36.36Z"/>
        <path d="M18.46,12.93a.42.42,0,0,0-.42.42v5.23a.42.42,0,0,0,.84,0V13.35A.42.42,0,0,0,18.46,12.93Z"/>
        <path d="M18.46,11.61a.48.48,0,1,0,0,1,.48.48,0,0,0,0-1Z"/>
      </svg>
    )
  }
}

export default ArrowDown;
