import React, { Component } from 'react'

class ArrowDown extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="arrow-down"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 50"
      >
        <title>arrow-down</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M16.19,49.43a1.28,1.28,0,0,0,.4-.27h0L31.06,34.68a1.23,1.23,0,0,0,0-1.75,1.24,1.24,0,0,0-1.75,0L17,45.29v-44a1.24,1.24,0,1,0-2.47,0V45.29L2.11,32.93a1.24,1.24,0,0,0-1.75,0,1.26,1.26,0,0,0-.36.88,1.22,1.22,0,0,0,.36.87L14.84,49.16h0a1.18,1.18,0,0,0,.4.27,1.23,1.23,0,0,0,.47.09,1.28,1.28,0,0,0,.48-.09"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default ArrowDown;
