import React, { Component } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import FAQ from '../../Components/ContentBlock/FAQ'
import Button from '../../Components/Buttons/Primary'
import WorkLayout from '../../layouts/work'
import Loading from '../../Components/Loading'
import ColorBlock from '../../Components/ContentBlock/ColorBlock'
import QuestionList from '../../Components/List/QuestionList'
import CheckList from '../../Components/List/CheckList'

class Coaching extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
      products: {
        coaching: {
          'price': '$100',
          'link': 'https://itsmedrew.gumroad.com/l/coaching'
        },
        workshop: {
          'price': '$800',
          'link': 'https://itsmedrew.gumroad.com/l/workshop'
        }
      },
      faqs: [
        {
          question: "I’m a former CareerFoundry student, can I get a discount?",
          answer: "I'm glad that you've enjoyed our sessions so much that you want to continue! Reach out to me to receive a discount code as a thank you."
        },
        {
          question: "What is messaging access?",
          answer: "This awesome benefit allows you to contact me pre and post session with any questions. Allow 24 hours response time, however I am usually pretty quick :D"
        },
        {
          question: "Can we use another platform instead of Zoom?",
          answer: "Sure! Although I prefer Zoom, contact me ahead of our scheduled meeting if you prefer to use another platform."
        },
        {
          question: "What is your refund policy?",
          answer: "Change of heart? Schedule conflicts? No problem, be sure to notify me greater than 24 hours in advance for full money back or to reschedule. If it's within the 24 hour period, you can only reschedule for a later date."
        }
      ]
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true })
    }, 1000)
  }

  render() {
    if (!this.state.loaded) { return <Loading /> }

    const { faqs, products } = this.state;

    return (
      <WorkLayout header="secondary" className="coaching">
        <ColorBlock className="coaching-hero" title="Level Up: Fast Track Your UX Journey" color="yellow" isCenter isHero>
          <Container>
            <Row>
              <Col md={12} lg={12} xl={8} push={{md: 0, lg: 0, xl: 2}}>
                <p>Ever felt lost in your UX journey? Whether you're transitioning from web development, just starting out, or are an experienced designer looking to sharpen your edge, I'm here to guide, inspire, and elevate you to your potential.</p>
                <QuestionList>
                  <li><b>First job</b> playing hard to get?</li>
                  <li><b>Just graduated a bootcamp</b> and not sure where to go next?</li>
                  <li><b>Resume & portfolio</b> not performing like it should?</li>
                  <li><b>Battling imposter syndrome</b> in your new job?</li>
                  <li><b>Skills need a refresh</b> after being in the same role for years?</li>
                  <li><b>Struggling in interviews</b> and not receiving call backs?</li>
                </QuestionList>
                <p>I’ve been on both sides of the interview, and can help you overcome these obstacles <b>to get on the right path, quickly!</b></p>
                <div className="button-group coaching-hero__cta">
                  <Button chevron to={products.coaching.link} external>1 Hour 1:1 Session <span className="cost purple">{products.coaching.price}</span></Button>
                  <Button chevron to={products.workshop.link} external>All-Day Workshop <span className="cost green">{products.workshop.price}</span></Button>
                </div>
              </Col>
            </Row>
          </Container>
        </ColorBlock>
        <Container>
          <Row className="row pricing-options">
            <Col>
              <ColorBlock className="pricing" title="1 Hour 1:1 Session" color="purple" isDecor isCenter>
                <div className="pricing__content">
                  <p>Whatever challenge you’re facing, you don’t have to go at it alone. It’s time you get a fresh perspective and start enjoying designing again.</p>
                  <CheckList className="pricing__checklist">
                    <li>1 Hour Zoom Meeting</li>
                    <li>Meeting recording</li>
                    <li>Detailed action plan to achieve your goals</li>
                    <li>Messaging access</li>
                  </CheckList>
                </div>
                <div className="pricing__cta">
                  <Button external chevron to={products.coaching.link}>1 Hour 1:1 Session <span className="cost yellow">{products.coaching.price}</span></Button>
                </div>
              </ColorBlock>
              <ColorBlock className="pricing" title="All-Day Workshop" color="gradient" isDecor isCenter>
                <div className="pricing__content">
                  <p>Have a complicated project you need help working on? Let’s pair together to design an awesome solution - learning along the way.</p>
                  <CheckList className="pricing__checklist">
                    <li>8-hour intense virtual working session (weekends only)</li>
                    <li>Multiple participants are welcome</li>
                    <li>Collaboration tools included (Miro, Figma, etc.)</li>
                    <li>Pre-planning prior to the workshop</li>
                    <li>Messaging access</li>
                  </CheckList>
                </div>
                <div className="pricing__cta">
                  <Button external chevron to={products.workshop.link}>All-Day Workshop <span className="cost yellow">{products.workshop.price}</span></Button>
                </div>
              </ColorBlock>
            </Col>
          </Row>
          <Row>
            <FAQ faqs={faqs}></FAQ>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default Coaching;
