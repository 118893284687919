import React, { Component } from 'react'

class Slideshow extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="slideshow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 96 960 960"
      >
        <title>slideshow</title>
        <path d="m381 755 278-179-278-180v359ZM189 961q-39.05 0-66.525-27.475Q95 906.05 95 867V285q0-39.463 27.475-67.231Q149.95 190 189 190h582q39.463 0 67.231 27.769Q866 245.537 866 285v582q0 39.05-27.769 66.525Q810.463 961 771 961H189Zm0-94h582V285H189v582Zm0-582v582-582Z"/>
      </svg>
    )
  }
}

export default Slideshow;
