import React, { Component } from 'react'

class CustomerTypeDt extends Component {
  render() {
    const styles = [
      {fill: ""},
      {fill: "#0cf0f3"},
      {fill: "#ffdfba"},
      {fill: "#ffa4b8"},
      {fill: "#d6f4fc"},
      {fill: "#c79a83"},
      {fill: "#ffebd2"},
      {fill: "#ffd6a6"},
      {fill: "#e0b7a3"},
      {fill: "#fff3e4"},
      {fill: "#ff99af"},
      {fill: "#bdeffc"}
    ]

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="customer-type-lc"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
      >
        <title>lc</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path style={styles[1]} d="M25,0A25,25,0,0,0,10.26,45.18,8.06,8.06,0,0,1,12,43.24V18.14A10.65,10.65,0,0,1,22.61,7.5h4.94A10.65,10.65,0,0,1,38.19,18.14V39.56a.79.79,0,0,1-.79.79v2.36a8.3,8.3,0,0,1,2.39,2.44A25,25,0,0,0,25,0Z"/>
            <path style={styles[2]} d="M28.8,38.18h0c0,.1,0,.2,0,.29A2.71,2.71,0,0,1,28.8,38.18Z"/>
            <path style={styles[2]} d="M28.72,40.59A.79.79,0,0,1,29,39.5l.06,0c-.05-.16-.1-.32-.14-.48v0a9.86,9.86,0,0,1-7,.26,4.77,4.77,0,0,1-1.46,2.42l.06.4L25,45.59l1.84-1.43a.79.79,0,0,1,1.11.13l1.41-1.09.23-1.51A4.38,4.38,0,0,1,28.72,40.59Z"/>
            <polygon style={styles[3]} points="27.99 44.3 29.39 43.22 29.39 43.2 27.98 44.29 27.99 44.3"/>
            <path style={styles[3]} d="M30.7,45.08,30,49.5a25.41,25.41,0,0,0,5.62-1.87A7.52,7.52,0,0,0,30.7,45.08Z"/>
            <path style={styles[3]} d="M14.41,47.65a24.88,24.88,0,0,0,5.64,1.86l-.69-4.43A7.47,7.47,0,0,0,14.41,47.65Z"/>
            <path style={styles[4]} d="M28,44.3h0a.78.78,0,0,1-.16,1.11L25.5,47.22a.78.78,0,0,1-1,0L22.19,45.4l.69,4.5c.7.06,1.41.1,2.12.1a25.14,25.14,0,0,0,3.38-.23l1-6.55Z"/>
            <path style={styles[5]} d="M36.61,39.56V18.14A9,9,0,0,0,32,10.24a1.17,1.17,0,0,1-1.2,1H25.58a9.84,9.84,0,0,0-9.85,9.85V25.5a1.12,1.12,0,0,1-.29.77.74.74,0,0,1,.49-.26l.85-.13h0a.79.79,0,0,1,.61-.93,19.22,19.22,0,0,0,11.63-7.25.79.79,0,0,1,1.36.19,7.21,7.21,0,0,0,4.75,4.23.81.81,0,0,1,.45.72v5.54a10.59,10.59,0,0,1-6.81,9.81,2.71,2.71,0,0,0,0,.29v0a1.88,1.88,0,0,0,.05.33l0,.15c0,.16.09.32.14.48a.81.81,0,0,1,1,.29,3.26,3.26,0,0,0,2.83,1.58,8.18,8.18,0,0,1,4.5,1.37V40.35A.79.79,0,0,1,36.61,39.56Z"/>
            <path style={styles[5]} d="M15.23,27.18v0Z"/>
            <path style={styles[5]} d="M15.73,33.34v8s0,.08,0,.12a7.85,7.85,0,0,1,1.46-.14,3.28,3.28,0,0,0,2.33-1,3.32,3.32,0,0,0,1-2.34v-.21A10.6,10.6,0,0,1,15.73,33.34Z"/>
            <path style={styles[5]} d="M15.28,26.54a.63.63,0,0,0,0,.26A.75.75,0,0,1,15.28,26.54Z"/>
            <path style={styles[6]} d="M34,28.28V23.4a8.58,8.58,0,0,1-4.52-3.77,21.06,21.06,0,0,1-11.35,6.78,1.09,1.09,0,0,1,.08.31,9.92,9.92,0,0,0,4.6,7.83,1.58,1.58,0,0,1,.25,2.52,8.92,8.92,0,0,0,1.93.22A9,9,0,0,0,34,28.28Z"/>
            <path style={styles[7]} d="M28.9,39l0-.15a1.88,1.88,0,0,1-.05-.33v0c0-.09,0-.19,0-.29a10.42,10.42,0,0,1-6.75.27,4.39,4.39,0,0,1-.14.82,9.86,9.86,0,0,0,7-.26Z"/>
            <path style={styles[8]} d="M27.55,9.08H22.61a9.06,9.06,0,0,0-9.06,9.06V42.2a8.22,8.22,0,0,1,2.17-.7s0-.08,0-.12v-8a10.47,10.47,0,0,1-1.29-4.89V28a.8.8,0,0,1,.79-.79V26.8a.63.63,0,0,1,0-.26l0-.05a.82.82,0,0,1,.12-.2s0,0,0,0a1.12,1.12,0,0,0,.29-.77V21.11a9.84,9.84,0,0,1,9.85-9.85h5.19a1.17,1.17,0,0,0,1.2-1A9,9,0,0,0,27.55,9.08Z"/>
            <path style={styles[9]} d="M22.85,34.55a9.92,9.92,0,0,1-4.6-7.83,1.09,1.09,0,0,0-.08-.31,5.39,5.39,0,0,1-.59.1.81.81,0,0,1-.77-.62h0l-.85.13a.74.74,0,0,0-.49.26s0,0,0,0a.82.82,0,0,0-.12.2l0,.05a.75.75,0,0,0,0,.26v.38h0A.8.8,0,0,1,16,28a9,9,0,0,0,7.07,9.1A1.58,1.58,0,0,0,22.85,34.55Z"/>
            <path style={styles[10]} d="M17.18,42.94a6.51,6.51,0,0,0-4.67,1.93,6.84,6.84,0,0,0-.95,1.21,26.18,26.18,0,0,0,2.85,1.57,7.47,7.47,0,0,1,4.95-2.57L19,42.59A4.77,4.77,0,0,1,17.18,42.94Z"/>
            <path style={styles[10]} d="M32.89,42.94a4.78,4.78,0,0,1-1.81-.35l-.38,2.49a7.52,7.52,0,0,1,4.94,2.55,27.07,27.07,0,0,0,2.84-1.58A6.59,6.59,0,0,0,32.89,42.94Z"/>
            <path style={styles[11]} d="M22.19,45.4l-1.34-1,.83,5.41c.4.06.8.1,1.2.13Z"/>
            <path d="M37.4,42.71a8.18,8.18,0,0,0-4.5-1.37,3.26,3.26,0,0,1-2.83-1.58.81.81,0,0,0-1-.29l-.06,0a.79.79,0,0,0-.26,1.09,4.38,4.38,0,0,0,.9,1.1l-.23,1.51v0l-1,6.55.81-.13L30,49.5l.68-4.42.38-2.49a4.78,4.78,0,0,0,1.81.35,6.59,6.59,0,0,1,5.59,3.11l.66-.43h0l.65-.47A8.3,8.3,0,0,0,37.4,42.71Z"/>
            <path d="M28,44.3h0a.79.79,0,0,0-1.11-.13L25,45.59l-4.53-3.5-.06-.4a4.77,4.77,0,0,0,1.46-2.42,4.39,4.39,0,0,0,.14-.82,10.42,10.42,0,0,0,6.75-.27h0a10.59,10.59,0,0,0,6.81-9.81V22.83a.81.81,0,0,0-.45-.72,7.21,7.21,0,0,1-4.75-4.23.79.79,0,0,0-1.36-.19,19.22,19.22,0,0,1-11.63,7.25.79.79,0,0,0-.61.93h0a.81.81,0,0,0,.77.62,5.39,5.39,0,0,0,.59-.1,21.06,21.06,0,0,0,11.35-6.78A8.58,8.58,0,0,0,34,23.4v4.88a9,9,0,0,1-9,9,8.92,8.92,0,0,1-1.93-.22A9,9,0,0,1,16,28a.8.8,0,0,0-.78-.79h0a.8.8,0,0,0-.79.79v.48a10.47,10.47,0,0,0,1.29,4.89,10.6,10.6,0,0,0,4.75,4.5v.21a3.32,3.32,0,0,1-1,2.34,3.28,3.28,0,0,1-2.33,1,7.85,7.85,0,0,0-1.46.14,8.22,8.22,0,0,0-2.17.7V18.14a9.06,9.06,0,0,1,9.06-9.06h4.94a9.08,9.08,0,0,1,9.06,9.06V39.56a.79.79,0,0,0,1.58,0V18.14A10.65,10.65,0,0,0,27.55,7.5H22.61A10.65,10.65,0,0,0,12,18.14v25.1a8.06,8.06,0,0,0-1.71,1.94l.64.47h0c.21.15.43.29.65.43a6.84,6.84,0,0,1,.95-1.21,6.51,6.51,0,0,1,4.67-1.93,4.77,4.77,0,0,0,1.8-.35l.38,2.49.69,4.43.81.14.82.12-.83-5.41,1.34,1,2.35,1.82a.78.78,0,0,0,1,0l2.33-1.81A.78.78,0,0,0,28,44.3Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default CustomerTypeDt;
