import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-grid-system'
import classnames from 'classnames'
import Button from '../../../Components/Buttons/Secondary'
import LinkWithIcon from '../../../Components/Links/IconLabel'

class SecondaryPageHeader extends Component {
  renderContent = () => {
    const { featuredImage, title, time, prototypeId, figmaLink, fullLink, children, role, deck } = this.props

    let prototypeEl
    let featuredImageEl
    let headerEl
    let roleDetailEl
    let timeDetailEl
    let figmaLinkEl
    let fullLinkEl

    if (prototypeId) {
      prototypeEl = <Button to={prototypeId} internal="true" buttonType="solid" className="secondary-ph__btn btn btn-solid">Jump to Prototype</Button>
    }

    if (deck) {
      prototypeEl = <Button to={deck} external="true" buttonType="solid" className="secondary-ph__btn btn btn-solid">View Full Presenation</Button>
    }

    if (figmaLink) {
      figmaLinkEl = <Button to={figmaLink} external="true" buttonType="solid" className="secondary-ph__btn btn btn-solid">View Figma Prototype</Button>
    }

    if (fullLink) {
      fullLinkEl = <LinkWithIcon className="work__link" to={ fullLink } icon="lock">See Full Case Study</LinkWithIcon>
    }

    if (role) {
      roleDetailEl = <h4 className="secondary-ph__subtitle">{ role }</h4>
    }

    if (time) {
      timeDetailEl = <h4 className="secondary-ph__subtitle">{ time }</h4>
    }

    headerEl = <header className="secondary-ph__header">
                  <h1 className="secondary-ph__title">{ title }</h1>
                  <div className="secondary-ph__details">
                    { timeDetailEl } { roleDetailEl }
                  </div>
                  { prototypeEl }
                  { figmaLinkEl }
                  { fullLinkEl }
                </header>

    if (featuredImage) {
      featuredImageEl = <img className="secondary-ph__image" src={ featuredImage } alt={ title }/>
    }

    return (
      <div>
        <Row>
          <Col md={8} push={{md: 2}}>
            { headerEl }
          </Col>
        </Row>
        <Row>
          <Col md={8} push={{md: 2}}>
            <div className="secondary-ph__body">
              { children }
            </div>
          </Col>
        </Row>
        <Row>
          <Col align="center" className="secondary-ph__preview">
            { featuredImageEl }
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    return (
      <section className={classnames('secondary-ph', this.props.className)}>
        <Container className="secondary-ph__content">
          { this.renderContent() }
        </Container>
      </section>
    )
  }
}

SecondaryPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  featuredImage: PropTypes.string,
  updated: PropTypes.string,
  prototypeId: PropTypes.string,
  deck: PropTypes.string
}

export default SecondaryPageHeader
