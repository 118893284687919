import {
  FETCH_FEEDBACK_REQUEST,
  FETCH_FEEDBACK_SUCCESS,
  FETCH_FEEDBACK_ERROR,
} from '../constants';

// eslint-disable-next-line
export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_FEEDBACK_REQUEST:
    case FETCH_FEEDBACK_ERROR:
      return state;

    case FETCH_FEEDBACK_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
