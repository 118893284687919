import React, { Component } from 'react'

class ChevronLeft extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>chevron-left</title>
        <polygon points="15.62 20 16.39 19.23 9.15 12 16.39 4.77 15.62 4 7.62 12 15.62 20"/>
      </svg>
    )
  }
}

export default ChevronLeft;
