import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import Icon from '../../../icons'

class ButtonPrimary extends Component {
  renderClick(e) {
    e.preventDefault();

    this.props.onClick();
  }

  render() {
    const {to, children, className, type, external, chevron} = this.props

    let chevronEl

    if (chevron) {
      chevronEl = <Icon className="btn-primary__trailing-icon" name="chevron-right" />
    }

    if (external) {
      return (
        <a className={classnames({[`btn-primary--${type}`]: true}, className)} href={ to } target="_blank" rel="noopener noreferrer">{children}{chevronEl}</a>
      )
    }

    return (
      <NavLink className={classnames({[`btn-primary--${type}`]: true}, className)} to={ to }>{children}</NavLink>
    )
  }
}

ButtonPrimary.defaultProps = {
  type: 'solid',
  external: false,
  chevron: false
}

ButtonPrimary.propTypes = {
  chevron: PropTypes.bool,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  type: PropTypes.string.isRequired
}

export default ButtonPrimary;
