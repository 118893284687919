import React, { Component } from 'react'

class LogoDrew extends Component {
  render() {
    return (
      <svg className="logo-drew" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.53 43.65">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon className="cls-2" points="6.87 38.2 7.13 40.4 21.2 40.4 21.47 38.14 22.29 31.32 6.04 31.32 6.87 38.2"/>
            <polygon className="cls-2" points="2.98 5.75 4.07 14.84 24.26 14.84 25.35 5.75 2.98 5.75"/>
            <polygon className="cls-3" points="25.35 5.75 24.26 14.84 25.71 14.84 26.8 5.75 25.35 5.75"/>
            <polygon className="cls-3" points="21.47 38.14 21.2 40.4 22.65 40.4 22.89 38.37 23.73 31.32 22.29 31.32 21.47 38.14"/>
            <polygon className="cls-4" points="26.6 15.84 26.1 15.84 25.59 15.84 24.14 15.84 4.18 15.84 3.68 15.84 3.18 15.84 2.08 15.84 4.14 30.32 4.92 30.32 5.42 30.32 5.92 30.32 22.41 30.32 23.86 30.32 24.36 30.32 24.86 30.32 25.63 30.32 27.7 15.84 26.6 15.84"/>
            <path className="cls-4" d="M12.15,21.14h-.22v1.68h.21c.39,0,.58-.31.58-.92S12.53,21.14,12.15,21.14Z"/>
            <path className="cls-4" d="M7.58,21.24H7.26v4.12h.25a.76.76,0,0,0,.74-.53,4,4,0,0,0,.24-1.6,3.75,3.75,0,0,0-.22-1.5A.73.73,0,0,0,7.58,21.24Z"/>
            <path d="M26.72,14.84,27.93,4.75H1.85L3.06,14.84H.93L3.28,31.32H5l.84,7,.37,3H23.53l.34-2.84.87-7.24H26.5l2.35-16.48ZM24.86,30.32H4.14L2.08,15.84H27.7L25.63,30.32ZM22.65,40.4H7.13l-.26-2.19L6,31.32h17.7l-.85,7Zm1.61-25.56H4.06L3,5.75H26.8l-1.09,9.09Z"/>
            <path d="M7.57,19.63H6.14V27H7.48a1.76,1.76,0,0,0,1.6-1,5.64,5.64,0,0,0,.58-2.83,5,5,0,0,0-.55-2.62A1.69,1.69,0,0,0,7.57,19.63Zm.68,5.2a.76.76,0,0,1-.74.53H7.26V21.24h.32a.73.73,0,0,1,.69.49,3.75,3.75,0,0,1,.22,1.5A4,4,0,0,1,8.25,24.83Z"/>
            <path d="M13.86,21.81c0-1.45-.57-2.18-1.69-2.18H10.81V27h1.12V24.31h.28L13.09,27h1.27l-1.21-3.21A2.64,2.64,0,0,0,13.86,21.81Zm-1.72,1h-.21V21.14h.22c.38,0,.57.25.57.76S12.53,22.82,12.14,22.82Z"/>
            <polygon points="15.14 26.99 17.61 26.99 17.61 25.37 16.27 25.37 16.27 23.98 17.51 23.98 17.51 22.39 16.27 22.39 16.27 21.23 17.61 21.23 17.61 19.63 15.14 19.63 15.14 26.99"/>
            <path d="M23.16,19.63l-.46,3.7c-.11.92-.18,1.57-.2,1.94,0-.29-.06-.72-.13-1.31s-.12-1.1-.18-1.54l-.36-2.79h-1l-.37,2.79c-.05.38-.11.87-.18,1.46s-.1,1.05-.13,1.39q-.06-.72-.21-1.95l-.45-3.69H18.38l1,7.36h1.32c.24-1.93.38-3,.41-3.3s.07-.57.1-.89.06-.6.08-.82c0,.27.05.61.09,1s.07.67.09.8L21.89,27h1.33l1-7.36H23.16Z"/>
            <polygon className="cls-5" points="27.03 2.38 27.03 0 2.42 0 2.42 2.38 0 2.38 0 5.75 29.45 5.75 29.45 2.38 27.03 2.38"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default LogoDrew;
