import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './home'
import PasswordGate from './password-gate'
import Connect from './work/connect'
import Doordash from './work/doordash'
import Trapink from './work/trapink'
import NextechDesignSystem from './work/nextech-design-system'
import ProductPage from './work/product-page'
import Espresso from './work/espresso'
import Bio from './bio'
import Coaching from './coaching'
import IwDesignSprint from './work/workshops/iw-design-sprint'
import PalzTapHouse from './work/other/palz-tap-house'
import WalmartBackorder from './work/walmart-backorder'
import PublixTaxCS from './work/publix-tax/case-study'
import PublixTaxPreview from './work/publix-tax'
import Login from './login'

class MainLayout extends Component {
  render() {
    return (
      <Switch>
        <Route path='/' exact component={ Home }/>
        <Route path="/password-gate" render={ ()=><PasswordGate /> }/>
        <Route path="/bio" render={ ()=><Bio /> }/>
        <Route path="/coaching" render={ ()=><Coaching /> }/>
        <Route path="/work/doordash" render={ ()=><Doordash /> }/>
        <Route path="/work/connect" component={ Connect }/>
        <Route path="/work/espresso" component={ Espresso }/>
        <Route path="/work/trapink" render={ ()=><Trapink /> }/>
        <Route path="/work/nextech-design-system" render={ ()=><NextechDesignSystem /> }/>
        <Route path="/work/product-page" render={ ()=><ProductPage /> }/>
        <Route path="/work/workshops/iw-design-sprint" render={ ()=><IwDesignSprint /> }/>
        <Route path="/work/other/palz-tap-house" render={ ()=><PalzTapHouse /> }/>
        <Route path="/work/walmart-backorder" render={ ()=><WalmartBackorder /> }/>
        <Route path="/work/publix-tax/case-study" render={ ()=><PublixTaxCS /> }/>
        <Route path="/work/publix-tax" render={ ()=><PublixTaxPreview /> }/>
        <Route path="/login" render={ ()=><Login /> }/>
      </Switch>
    );
  }
}

export default MainLayout;
