import React from 'react';
import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import ArrowDown from './arrow-down';
import CaratDown from './carat-down';
import Like from './like';
import Dislike from './dislike';
import Linkedin from './linkedin';
import Dribbble from './dribbble';
import Slideshow from './slideshow';
import Pdf from './pdf';
import Lock from './lock';
import Computer from './computer';
import User from './user';
import Youtube from './youtube';

const Icon = props => {
  switch(props.name) {
    case "chevron-left":
      return <ChevronLeft {...props} />;
    case "chevron-right":
      return <ChevronRight {...props} />;
    case "arrow-down":
      return <ArrowDown {...props} />;
    case "carat-down":
      return <CaratDown {...props} />;
    case "like":
      return <Like {...props} />;
    case "dislike":
      return <Dislike {...props} />;
    case "linkedin":
      return <Linkedin {...props} />;
    case "dribbble":
      return <Dribbble {...props} />;
    case "slideshow":
      return <Slideshow {...props} />;
    case "pdf":
      return <Pdf {...props} />;
    case "lock":
      return <Lock {...props} />;
    case "computer":
      return <Computer {...props} />;
    case "user":
      return <User {...props} />;
    case "youtube":
      return <Youtube {...props} />;
    default:
      return <div />;
  }
}
export default Icon;
