import React, { Component } from 'react'

class Lock extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="lock"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 21"
      >
        <title>lock</title>
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <path d="m8,16c.55,0,1.02-.2,1.41-.59.39-.39.59-.86.59-1.41s-.2-1.02-.59-1.41c-.39-.39-.86-.59-1.41-.59s-1.02.2-1.41.59c-.39.39-.59.86-.59,1.41s.2,1.02.59,1.41c.39.39.86.59,1.41.59Z"/>
            <path d="m15.41,7.59c-.39-.39-.86-.59-1.41-.59h-1v-2c0-1.38-.49-2.56-1.46-3.54s-2.15-1.46-3.54-1.46-2.56.49-3.54,1.46-1.46,2.15-1.46,3.54v2h-1c-.55,0-1.02.2-1.41.59-.39.39-.59.86-.59,1.41v10c0,.55.2,1.02.59,1.41.39.39.86.59,1.41.59h12c.55,0,1.02-.2,1.41-.59.39-.39.59-.86.59-1.41v-10c0-.55-.2-1.02-.59-1.41Zm-10.41-2.59c0-.83.29-1.54.88-2.12.58-.58,1.29-.88,2.12-.88s1.54.29,2.12.88c.58.58.88,1.29.88,2.12v2h-6v-2Zm9,14H2v-10h12v10Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default Lock;
