import React, { Component } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import PageHeader from '../../../Components/PageHeaders/Secondary'
import HeaderImg from '../../../images/case-study-nextech-ds-header.jpg'
import HMWImg from '../../../images/nt-ds-hmw.jpg'
import PhasesImg from '../../../images/nt-ds-phases.jpg'
import POCImg from '../../../images/nt-ds-poc-1.jpg'
import CoreImg1 from '../../../images/nt-ds-core-1.jpg'
import CoreImg2 from '../../../images/nt-ds-core-2.jpg'
import LayersImg from '../../../images/nt-ds-layers-1.jpg'
import UsageImg from '../../../images/nt-ds-core-input.gif'
import ProgramsImg from '../../../images/nt-ds-programs.jpg'
import LayerStructure from '../../../images/nt-ds-layer-structure.png'
import OverviewPanel from '../../../Components/Panels/Overview'

function mapStateToProps(state) {
  return { page: state.pageList.nextechDesignSystem };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class NextechDesignSystem extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    return (
      <WorkLayout header="secondary">
        <PageHeader
            title="Nextech Design System"
            time="2021"
            featuredImage={HeaderImg}
            role="Lead Designer & Facilitator"
            >
            <OverviewPanel>
              Before building a UX Team in 2020, Nextech only had one designer responsible for all 11 applications. This issue combined with tight deadlines resulted in inconsistent designs, sloppy codebases, and poor client experience. I was tasked with leading efforts to establish a design system that would align our applications to be scalable enough to allow rapid expansion.
            </OverviewPanel>
          </PageHeader>
          <Container>
            <Row className="work__block">
              <Col className="work__column" md={12}>
                <img className="work__image" src={ProgramsImg} alt="programs used to create the design system"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Starting with research</h2>
                <p>I began by demonstrating to the design team the <a href="https://bradfrost.com/blog/post/atomic-web-design/" target="_blank" rel="noopener noreferrer">atomic design</a> concept and what I’ve created previously. Next, I facilitated a remote workshop using Lucidspark to help us discover our challenges and goals. We concluded the new design system will need to:</p>
                <ol>
                  <li>Create each component state as a separate symbol for quick prototyping</li>
                  <li>Combine atoms and molecules into a simple “Components” page as the design team felt the atomic concept was a little confusing</li>
                  <li>Follow material UI as much as possible as the development teams were committed to utilizing Angular Material</li>
                </ol>
                <img src={HMWImg} alt="Workshop How Might We"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Planning a phased approach</h2>
                <p>The team decided due to the large scale and complexity of adopting a design system into 11 existing software applications we would use a detailed phased approach to creation and adoption. I would learn later this would be drastically accelerated.</p>
                <img src={PhasesImg} alt="Workshop Phased Approach"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Creating a proof of concept</h2>
                <p>Having created a full-scale design system previously I had a clear idea of how I wanted to lay this out in Sketch. I was excited to utilize Sketch's new color variable feature. I decided to create a basic working library that would create a discussion on important decisions we needed to make up front such as naming conventions, structure, and scalability.</p>
                <img src={POCImg} alt="Proof of concept"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Creating the Core</h2>
                <p>Now that the strategy and concept were validated with the design team, I began creating the Core. For this I implemented Abstract app as a way for the team to easily collaborate. The team meets weekly to demo the progress, gather feedback, and discuss a list of topics.</p>
                <img src={CoreImg1} alt="Screenshot of the Core design system" style={{width: "100%"}} />
                <img src={CoreImg2} alt="Screenshot of the Core design system" style={{width: "100%"}} />
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Creating Dynamic Symbols</h2>
                <p>It is a constant challenge to balance creating a dynamic symbol with ease of use while prototyping. You also have to take into account how developers will implement these components when creating them.</p>
                <p>I decided to create a principle that our components should be non-descriptive (blue button vs. primary button) and as dynamic as possible. For example, if we’re creating a new table header we need to think about how this could also be used for other platforms and name the symbol overrides accordingly. Here is an example of how I created a dynamic input symbol.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6}>
                <header className="work-header">
                  <h3 className="work-header__title">Layer Structure</h3>
                </header>
                <p>Instead of adding a new rectangle, all shapes are added as symbols with a pre-defined border-radius. For inputs, I added the label as a symbol (child element of inputs) so that you can still have one symbol and override the label to choose text or text + icon.</p>
                <img src={LayerStructure} alt="Layer Structure Example"/>
              </Col>
              <Col className="work__column" md={6} push={{ md: 0 }}>
                <img className="work__image" src={LayersImg} alt="Layers example in Sketch"/>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={6} push={{ md: 0 }}>
                <img className="work__image" src={UsageImg} alt="Product Page Wireframes"/>
              </Col>
              <Col className="work__column" md={6}>
                <header className="work-header">
                  <h3 className="work-header__title">One component... many states</h3>
                </header>
                <p>Another challenge I faced was a request from the team to make the label automatically transition from placeholder to label instead of manually having to retype the label.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Accelerating the roadmap</h2>
                <p>The previous timeline was approved and tied to my annual goals. The business decided to accelerate the roadmap by adding resources and begin adopting the design system and angular rewrites immediately. Luckily the core was in a great spot, however, each child library has not yet been created. Currently, the team is in the process of aligning the core with development and creating the child libraries one major platform at a time while supporting existing projects.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>Documentation</h2>
                <p>Our initial strategy for documentation was to create design guidelines using ZeroHeight for best practices and Storybook for developer documentation. We ended up switching to Lingo app due to pricing and hiring another resource to expedite our documentation efforts.</p>
              </Col>
            </Row>
            <Row className="work__block">
              <Col className="work__column" md={8} push={{md: 2}}>
                <h2>What I've learned so far...</h2>
                <p>The major takeaway I have so far is that as much as atomic design makes sense in concept, it is was not perfect for us when transitioning to development. The developers stated they will not be putting each small atom as a new component while separating atoms and molecules into their respective folders. Every molecule and organism to the developers will be a component, so we needed to adapt to this for better alignment.</p>
                <p>Also, I am currently learning how we will implement design tokens effectively as the concept is nothing new when I developed in SCSS but using JSON for this is new to me.</p>
                <p>Stay tuned as this continues to mature and grow.</p>
              </Col>
            </Row>
            <Row className="work__block work__conclusion">
              <Col xs={8} push={{xs: 2}}>
                <Rating {...this.props} />
              </Col>
            </Row>
          </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(NextechDesignSystem)
);
