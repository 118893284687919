import React, { Component } from 'react'

class Dribbble extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="dribbble"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.4 22.81"
      >
        <title>dribbble</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M23.25,14l-2.17-9.5-2-.95-.91-.44L11.7,0,6.78,2.37l-1,.5L2.32,4.52.66,11.76l-.23,1L0,14.67l4.5,5.64.64.8,1.35,1.7H16.91l.83-1,.9-1.12L23.13,15l.27-.34Zm-3-8.81,2,8.72a35.15,35.15,0,0,0-5.51,0,28.19,28.19,0,0,0-3-5,26.33,26.33,0,0,0,4.79-4.49ZM11.7,1.11l5.85,2.82a25.12,25.12,0,0,1-4.46,4.19A28.83,28.83,0,0,0,7.73,3ZM3.19,5.21l3.52-1.7a27,27,0,0,1,5.55,5.16A20.14,20.14,0,0,1,1.69,11.75ZM1.08,14.42l.38-1.66a21.17,21.17,0,0,0,11.4-3.34A26.93,26.93,0,0,1,15.65,14a15.44,15.44,0,0,0-10.5,5.54Zm15.34,7.39H7l-1.19-1.5a14.55,14.55,0,0,1,10.29-5.4,21.57,21.57,0,0,1,1.53,5.42Zm2-2.52a22.87,22.87,0,0,0-1.33-4.45,34.26,34.26,0,0,1,4.83.06Z"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default Dribbble;
