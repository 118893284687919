import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Illustration from '../../../illustrations'

class FeatureHighlight extends Component {
  render () {
    let imageEl

    const {illustration, image, title, text, alt} = this.props

    if (illustration) {
      imageEl =  <Illustration className="feature-highlight__icon" name={illustration} />
    }

    if (image) {
      imageEl = <img className="feature-highlight__image" src={image} alt={alt}/>
    }

    return (
      <div className="feature-highlight">
        {imageEl}
        <div className="feature-highlight__content">
          <h3 className="feature-highlight__title">{title}</h3>
          <span className="feature-highlight__text">{text}</span>
        </div>
      </div>
    )
  }
}

FeatureHighlight.propTypes = {
  illustration: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
  alt: PropTypes.string
}

export default FeatureHighlight;
