import React, { Component } from 'react'

class Youtube extends Component {
  render() {

    return (
      <svg
        width={ this.props.width }
        style={ this.props.style }
        className={ this.props.className }
        data-name="youtube"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 15.09"
      >
        <title>youtube</title>
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path d="m20.57,2.57c-.28-1.04-1.09-1.86-2.12-2.13-1.6-.43-7.69-.44-7.94-.44S4.16,0,2.56.44C1.52.72.71,1.53.43,2.57,0,4.19,0,7.41,0,7.55s0,3.36.43,4.97c.28,1.04,1.09,1.86,2.12,2.13,1.6.43,7.69.44,7.94.44s6.34,0,7.94-.44c1.03-.28,1.85-1.1,2.12-2.13.43-1.61.43-4.84.43-4.97s0-3.36-.43-4.97Zm-.97,9.69c-.18.69-.73,1.24-1.42,1.43-1.23.33-5.96.4-7.68.4s-6.46-.07-7.68-.4c-.69-.19-1.23-.73-1.41-1.43-.4-1.49-.4-4.68-.4-4.72s0-3.23.4-4.72c.18-.69.73-1.24,1.42-1.43,1.23-.33,5.96-.4,7.68-.4s6.46.07,7.68.4c.69.19,1.23.73,1.42,1.42h0c.4,1.49.4,4.69.4,4.72s0,3.23-.4,4.72Z"/>
              <polygon points="8.45 10.52 13.68 7.55 8.45 4.57 8.45 10.52"/>
            </g>
          </g>
      </svg>
    )
  }
}

export default Youtube;
